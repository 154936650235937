import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  path: '/resources',
  name: 'Resources',
  component: DefaultLayout,
  meta: {
    breadcrumb: 'Resources',
  },
  children: [
    {
      path: ':resourceType/:defaultFilters?',
      name: 'resource-list',
      meta: {
        breadcrumb: 'Resource List',
      },
      component: () => {
        return import('@/fabric/dashboard/resources/ResourceList.vue')
      },
    },
    {
      path: ':resourceType/taxonomy/:taxonomyId',
      name: 'term-list',
      meta: {
        breadcrumb: 'Term List',
      },
      component: () => {
        return import('@/fabric/dashboard/resources/ResourceTermList.vue')
      },
    },
    {
      path: ':resourceType/taxonomy/:taxonomyId/new',
      name: 'term-editor-new',
      meta: {
        breadcrumb: 'Term Editor',
      },
      component: () => {
        return import('@/fabric/dashboard/resources/ResourceTermEditor.vue')
      },
    },
    {
      path: ':resourceType/taxonomy/:taxonomyId/edit/:termId',
      name: 'term-editor',
      meta: {
        breadcrumb: 'Term Editor',
      },
      component: () => {
        return import('@/fabric/dashboard/resources/ResourceTermEditor.vue')
      },
    },
    {
      path: ':resourceType/edit/:resourceId',
      name: 'resource-editor',
      meta: {
        breadcrumb: 'Edit Resource',
      },
      component: () => import('@/fabric/dashboard/resources/ResourceEditor.vue'),
    },
    {
      path: ':resourceType/new',
      name: 'resource-editor-new',
      meta: {
        breadcrumb: 'New Resource',
      },
      component: () => import('@/fabric/dashboard/resources/ResourceEditor.vue'),
    }
  ]
};
