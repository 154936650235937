<template>
  <div>
    <label class="mb-[10px] fw-semibold">
      <template v-if="configuration">
        {{configuration.plural_name ?? child.label}}
      </template>

      <template v-else>
        {{child.label}}
      </template>
    </label>

    <div v-if="!configuration">
      <CSpinner size="sm"/> Loading...
    </div>

      <template v-if="configuration && attributes && taxonomies">
        <template v-for="(resource, resourceIndex) in resources" :key="'child-'+resources[resourceIndex].attributes.identifier">
          <ResourceEditorChild
            v-if="resource.getType() == childType && filter(resources[resourceIndex])"
            :sections="sections"
            :taxonomies="taxonomies"
            :base-attributes="attributes"
            :configuration="configuration"
            :wrapper-class="childWrapperClass"
            @deleted="deleted"
            @move-up="moveUp"
            @move-down="moveDown"
            :key="resources[resourceIndex].attributes.identifier"
            v-model="resources[resourceIndex]">
            <template #header-title="props">
              <slot name="header-title" :resource="props.resource">
                <div class="align-middle inline-block">
                  {{configuration.name ?? 'Entry'}}

                  <template v-if="configuration.highlight_attribute && resources[resourceIndex].attributes.attributes[configuration.highlight_attribute]">
                    ({{resources[resourceIndex].attributes.attributes[configuration.highlight_attribute]}})
                  </template>
                </div>
              </slot>
            </template>

            <template #header-controls="props">
              <slot name="header-controls" :resource="props.resource"></slot>
            </template>
          </ResourceEditorChild>

          <template v-if="resource.getType() == childType && filter(resources[resourceIndex])">
            <slot name="afterChild" :resource="resource"></slot>
          </template>
        </template>
      </template>

    <div class="text-right">
      <a href="#" v-if="configuration && showAddNew" class="btn btn-xs btn-primary mb-[20px]" @click.prevent="addNew">
        <CIcon :name="cilPlus()"/> Add New {{configuration.name}}
      </a>
    </div>
  </div>
</template>

<script>
import ResourceEditorSections from "@/fabric/dashboard/resources/ResourceEditorSections.vue";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem
} from "@coreui/vue-pro/dist/esm/components/accordion";
import {FabricResource} from "@/fabric/resource";
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";
import ResourceEditorChild from "@/fabric/dashboard/resources/ResourceEditorChild.vue";
import {cilTrashX} from "@coreui/icons-pro";
import {cilArrowThickFromBottom, cilArrowThickFromTop, cilPlus} from "@coreui/icons";
import {CIcon} from "@coreui/icons-vue";

export default {
  name: 'ResourceEditorChildEditor',
  components: {
    CIcon,
    ResourceEditorChild,
    CSpinner, CAccordionBody, CAccordionHeader, CAccordionItem, CAccordion, ResourceEditorSections},

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    childWrapperClass: {
      type: String,
      required: false,
      default: ''
    },

    parentId: {
      type: Number,
      required: false
    },

    child: {
      type: Object,
      required: true
    },

    childType: {
      type: String,
      required: true
    },

    filter: {
      type: () => true,
      required: false
    },

    defaultAttributes: {
      type: Object,
      default: {},
      required: false
    },

    showAddNew: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data()
  {
    return {
      resources: this.modelValue,
      taxonomies: null,
      attributes: null,
      sections: {},
      configuration: null
    }
  },

  methods: {
    cilPlus() {
      return cilPlus
    },
    cilArrowThickFromTop() {
      return cilArrowThickFromTop
    },
    cilArrowThickFromBottom() {
      return cilArrowThickFromBottom
    },
    cilTrashX() {
      return cilTrashX
    },
    deleted(resource)
    {
      this.$emit('deleted', resource);
    },

      addNew()
      {
          let attributes = this.defaultAttributes;
          let taxonomies = {};

          for (let attribute of this.attributes)
          {
              attributes[attribute.name] = null;
          }

          for (let taxonomy of this.taxonomies)
          {
              taxonomies[taxonomy.id] = [];
          }

          this.resources.push(new FabricResource({
              parent_resource_id: this.parentId,
              type: this.childType,
              attributes: attributes,
              taxonomies: taxonomies,
              sort_order: this.resources.length + 1,
              created_at: new Date(),
          }));
      },

    moveUp(childResource)
    {
      let childIndex = this.resources.indexOf(childResource);

      if (childIndex > 0)
      {
        this.resources.splice(childIndex, 1);
        this.resources.splice(childIndex - 1, 0, childResource);
      }

      for (let childIndex in this.resources)
      {
        this.resources[childIndex].attributes.sort_order = parseInt(childIndex) + 1;
      }
    },

    moveDown(childResource)
    {
      let childIndex = this.resources.indexOf(childResource);

      if (childIndex < this.resources.length - 1)
      {
        this.resources.splice(childIndex, 1);
        this.resources.splice(childIndex + 1, 0, childResource);
      }

      for (let childIndex in this.resources)
      {
        this.resources[childIndex].attributes.sort_order = parseInt(childIndex) + 1;
      }
    }
  },

  mounted()
  {
    this.$root.$fabric.resources
      .fetchAttributes(this.childType)
      .then((attributes) =>
      {
        this.attributes = attributes;
      })

    this.$root.$fabric.resources
      .fetchTaxonomies(this.childType)
      .then((taxonomies) =>
      {
        this.taxonomies = taxonomies;
      });

    this.$root.$fabric.resources
      .fetchEndpoint(this.childType, 'configuration')
      .then((configuration) =>
      {
        this.configuration = configuration;
      });
  },

  watch: {
    resource: {
      handler: function(newValue)
      {
        this.$emit('update:modelValue', newValue);
      },

      deep: true
    }
  }
}
</script>
