import { createApp } from 'vue'
import { createPinia } from 'pinia'
import {useFabric} from '@/fabric/fabric';
import Configurator from "@/configuration/Configurator";

import App from './App.vue'
import router from './router'

import CoreuiVue from '@coreui/vue-pro'

import * as linearIcons from '@coreui/icons-pro/dist/esm/linear';
import * as solidIcons from '@coreui/icons-pro/dist/esm/solid';
import {CIcon} from "@coreui/icons-vue";


const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(CoreuiVue, {
  colors: {
    "light":      "#aeeeed",
    "dark":       "#202322",
    "primary":    "#133f6a",
    "secondary":  "#0073be",
    "info":       "#2072f2",
    "success":    "#009955",
    "warning":    "#e0c800",
    "danger":     "#ef0b00",
  }
});

app.use(useFabric, {
  apiURL: apiURL,
  apiAuthorizationKey: localStorage.getItem('session') || '',
  dashboard: {
    title: dashboardTitle,
    menu: dashboardMenu,
  },
});

app.use(Configurator);

app.provide('icons', {
  ... linearIcons,
  ... solidIcons
});

app.component('CIcon', CIcon);

app.mount('#app');
