<template>
  <div class="form-control">
    <CFormLabel>{{attribute.label}}</CFormLabel>

    value={{value}}

    <div class="input-group mb-3">
      <span class="input-group-text">{{attribute.handler.attributes.currency_symbol}}</span>
      <input type="text" class="form-control" v-model="value">
    </div>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import {CDatePicker} from "@coreui/vue-pro/dist/esm/components/date-picker";
import {CFormLabel} from "@coreui/vue-pro/dist/esm/components/form";

export default {
  name: 'resource-editor-price-attribute',

  props: {
    modelValue: {
      type: [String, Date],
      required: true
    },

    attribute: {
      type: Object,
      required: true
    }
  },

  data()
  {
      return {
        value: this.modelValue,
        format,
        parse
      }
  },

  watch: {
    modelValue: {
      handler: function (value)
      {
        this.value = value;
      },

      deep: true
    },

    value: {
      handler: function (value)
      {
        this.$emit('update:modelValue', format(value, 'yyyy-MM-dd'));
      },

      deep: true
    }
  },

  components: {
    CFormLabel,
    CDatePicker
  }
}
</script>
