<template>
  <div class="form-control">
    <label class="mb-[10px]">{{attribute.label}}</label>

    <vue-tags-input
      v-model="model"
      :tags="value"
      :is-draggable="true"
      @tags-changed="change"
      placeholder="Add Item"
      :allow-edit-tags="true"
      @tag-order-changed="change"
      class="!w-[100%] !max-w-[100%]"
    />
  </div>
</template>

<script>
import {VueTagsInput} from '@sonht2219/vue3-tags-input';

export default {
  name: 'ResourceEditorListAttribute',

  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    attribute: {
      type: Object,
      required: true
    }
  },

  data()
  {
    let value = this.modelValue;

    if (!value || JSON.parse(value).length === 0)
    {
      value = [];
    }
    else
    {
      value = JSON.parse(this.modelValue);
    }

    return {
      value: value,
      mediaResource: null,
      model: [],
    }
  },

  methods: {
    change(newValue)
    {
      this.$emit('update:modelValue', JSON.stringify(newValue.map(item => item.text)));
    }
  },

  components: {
    VueTagsInput
  }
}
</script>
