<template>
  <div class="m-4">
    <h4 class="mb-3">
      {{configuration.plural_name}}

      <a href="#" class="btn btn-sm btn-primary ml-[20px]" @click.prevent="navigateToAddNew">Add New</a>
    </h4>

    <resource-list-filters :resource-configuration="resourceConfiguration" v-model="filters" class="mb-[10px]"/>

    <Collection results-per-page="15" :endpoint="endpoint" :filters="filters" order-by="-id" :table="resourceConfiguration.getTableBuilder($fabric)"></Collection>
  </div>
</template>
<script>
import Collection from '@/fabric/ui/Collection.vue';
import {CFormInput} from "@coreui/vue-pro/dist/esm/components/form";
import ResourceListFilters from "@/fabric/dashboard/resources/ResourceListFilters.vue";

export default {
  data()
  {
    let filters = {};

    // load default filters from params
    if (this.$route.params.defaultFilters)
    {
      let defaultFilters = JSON.parse(atob(this.$route.params.defaultFilters));

      if (typeof defaultFilters === 'object')
      {
        filters = defaultFilters;
      }
    }

    return {
      configuration: {
        plural_name: 'List'
      },

      filters: filters,

      searchQuery: ''
    }
  },

  computed: {
    resourceConfiguration()
    {
      return this.$root.$fabric.dashboard.getResourceConfiguration(
        this.$route.params.resourceType
      );
    },

    endpoint()
    {
      return this.$root.$fabric.config.apiURL + "resources/" + this.$route.params.resourceType;
    }
  },

  methods: {
      navigateToAddNew()
      {
        this.$router.push({
          name: 'resource-editor-new',
          params: {
            resourceType: this.$route.params.resourceType
          }
        })
      }
  },

  mounted()
  {
    this.$root.$fabric.axios.get(this.endpoint + '/configuration').then((response) =>
    {
        this.configuration = response.data;
    })
  },

  watch: {
    searchQuery(q)
    {
      this.filters.q = q;
    }
  },

  components: {
    ResourceListFilters,
    CFormInput,
    Collection
  }
}
</script>
