<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Feed</span>
      </h4>

      <div class="flex gap-[5px] flex-wrap justify-content-center">
        <template v-for="hit in hits.filter((hit) => hit.image)" :key="'hit_'+hit.id">
          <a :href="hit.meta.url" target="_blank" class="h-[300px] relative bg-cover bg-center rounded-[6px] overflow-hidden flex-shrink-0 min-w-[164px] sm:min-w-[272px]" :style="'background-image: url(\''+hit.image+'\')'">
            <div class="absolute top-0 text-[12px] bg-[rgba(0,0,0,0.5)] text-[#fff] p-[10px] right-0">
              {{hit.created_at_diff}}
            </div>

            <div class="absolute bottom-0 text-[14px] bg-[rgba(0,0,0,0.5)] text-[#fff] p-[10px] w-full">
              {{hit.name}}
              <template v-if="hit.meta.price">
                &nbsp;-&nbsp;{{hit.meta.price}}
              </template>
            </div>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data()
  {
    return {
      hits: [],
      intervalId: null,
      originalTitle: document.title,
      newHits: 0
    }
  },

  mounted()
  {
    console.log(notificationUrl);

    this.fetch_hits();

    this.interval = setInterval(() =>
    {
      this.fetch_hits();

      if (document.visibilityState === 'visible')
      {
        this.newHits = 0;
      }
      else if (this.newHits > 0)
      {
        document.title = this.originalTitle + ' ('+this.newHits+')';

        setTimeout(() => document.title = this.originalTitle, 2000);
      }

    }, 3000); // Fetch new captcha every minute
  },

  beforeUnmount()
  {
    clearInterval(this.interval);

    document.title = this.originalTitle;
  },

  methods: {
    fetch_hits()
    {
      this.$root.$fabric.axios.get('hits', {
        params: {
          flags: ['24h'],
          limit: 999,
          orderby: '-id'
        }
      })
        .then((response) => this.hits = response.data.results);
    }
  },

  watch: {
      hits: {
        deep: true,
        handler(hits, prevHits)
        {
          if (prevHits.length)
          {
            let discoveredHits = hits.filter(hit =>!prevHits.some(prevHit => prevHit.id === hit.id)).length;

            if (discoveredHits)
            {
              let audio = new Audio(notificationUrl);
              audio.play();
            }

            this.newHits += discoveredHits;
          }
        }
      }
  }
}
</script>
