<template>
  <span :class="{'resource-status': true, ['resource-'+entry.getType()+'-status']: true, ['resource-'+entry.getType()+'-status-'+this.entry.attributes.status]: true}">
    {{ status }}
  </span>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true
    },

    statuses: {
      type: Array,
      default: []
    }
  },

  computed: {
    status()
    {
      let status = this.statuses.find(status => status.id === this.entry.attributes.status);

      if (status)
      {
        return status.name;
      }

      return this.entry.attributes.status;
    }
  }
}
</script>
