<template>
  <a href="#" @click.prevent="navigateTo" class="link-dark text-decoration-none">
    {{ entry.attributes.name }}
  </a>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true
    }
  },

  methods: {
    navigateTo()
    {
      this.$router.push({
        name: 'term-editor',
        params: {
          resourceType: this.entry.attributes.resource_type,
          taxonomyId: this.entry.attributes.taxonomy,
          termId: this.entry.attributes.id
        }
      })
    }
  }
}
</script>
