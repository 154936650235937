<template>
  <div :class="{'text-[gray] add-block-section': true, 'flex align-items-center': !showSelector}">
    <span v-if="!showSelector" class="add-new !hidden text-xs cursor-pointer" @click.prevent="showSelector = true">
      Add New Content Block
    </span>

    <div v-if="showSelector" class="py-[20px]">
      <CMultiSelect label="Content Block"
                    :options="options"
                    :clear-search-on-select="true"
                    :multiple="false"
                    @change="addBlock"
      />
    </div>
  </div>
</template>

<script>
import {CFormInput, CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";
import {CIcon} from "@coreui/icons-vue";
import {cilPlusCircle} from "@coreui/icons-pro";
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";

export default {
  name: 'ResourceEditorBlockSelector',

  props: {
    blocks: {
      type: Array,
      required: true,
      default: () => []
    },

    resource: {
      type: Object,
      required: false
    },

    defaultShowSelector: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    cilPlusCircle() {
      return cilPlusCircle
    },

    addBlock(block)
    {
      if (block.length)
      {
        if (this.resource)
        {
          this.$emit('add-block', block[0].value, this.resource.getId())
        }
        else
        {
          this.$emit('add-block', block[0].value, null)
        }
      }

      this.showSelector = this.defaultShowSelector;
    }
  },

  data()
  {
    return {
      showSelector: this.defaultShowSelector,
    };
  },

  computed: {
      options()
      {
        return Object.values(this.blocks).map((block) =>
        {
          return {
            label: block.name,
            value: block.id
          }
        })
      }
  },

  components: {
    CMultiSelect,
    CFormSelect,
    CIcon,
    CFormInput,
  }
}
</script>

<style scoped>
.add-block-section {
  min-height: 24px;
  margin-top: -20px;
  margin-bottom: -20px;
}

.add-block-section:hover .add-new {
  display: inline-block !important;
}
</style>
