import {TableBuilder} from "@/fabric/ui/TableBuilder";
import {FabricResource} from "@/fabric/resource";
import {FilterBuilder} from "../../ui/FilterBuilder";
import {Axios} from "axios";
import {ref} from "vue";

class ResourceConfiguration
{
  type: string;
  axios: Axios;

  constructor(type: string, axios: Axios)
  {
    this.type = type;
    this.axios = axios;
  }

  getType(): string
  {
    return this.type;
  }

  getAxios(): Axios
  {
    return this.axios;
  }

  getTableBuilder(fabric): TableBuilder
  {
    let tableBuilder = new TableBuilder();

    fabric.resources.fetchStatuses(this.getType())
      .then(statuses => {
       tableBuilder.setAttribute('statuses', statuses);
      })

    tableBuilder.setTransformCallback(function (resource)
    {
      return new FabricResource(resource);
    });

    tableBuilder.setPrimaryColumnSize('w-[600px] xl:w-[500px] xl:min-w[450px]');

    // @ts-ignore
    tableBuilder
      .addColumn('name', 'Name', 0)
      .setSize('w-[300px]')
      .usingComponent(() => import("@/fabric/dashboard/resources/ResourceListName"));

    tableBuilder
      .addColumn('status', 'Status', 20)
      .setSize('w-[200px]')
      .usingComponent(() => import("@/fabric/dashboard/resources/ResourceListStatus"));

    tableBuilder
      .addColumn('created_at', 'Created', 30)
      .setSize('w-[200px]')
      .usingContentCallback(function (resource)
      {
        if (!resource.getCreatedAt())
        {
          return "N/A";
        }

        return resource.getCreatedAt().fromNow();
      });

    tableBuilder
      .addColumn('updated_at', 'Updated', 40)
      .setSize('w-[200px]')
      .usingContentCallback(function (resource)
      {
        if (!resource.getUpdatedAt())
        {
          return "N/A";
        }

        return resource.getUpdatedAt().fromNow();
      });

    return tableBuilder;
  }

  getTermsTableBuilder(): TableBuilder
  {
    let tableBuilder = new TableBuilder();

    tableBuilder.setTransformCallback(function (resource)
    {
      return new FabricResource(resource);
    });

    tableBuilder.setPrimaryColumnSize('w-[600px] xl:w-[600px] xl:min-w[350px]');

    // @ts-ignore
    tableBuilder
      .addColumn('name', 'Name')
      .setSize('w-[800px]')
      .usingComponent(() => import("@/fabric/dashboard/resources/ResourceTermName"));


    return tableBuilder;
  }

  getFilterBuilder(): FilterBuilder
  {
    let filters = new FilterBuilder();

    filters
      .addTextFilter('q', 'Search', 'w-[300px]', 0);

    filters.addSelectFilter('status', 'Status', () =>
    {
      let statuses = ref([]);

      this.getAxios().get('resources/'+this.getType()+'/statuses')
        .then((response) =>
        {
          for (let status of response.data.results)
          {
            statuses.value.push({
              label: status.name,
              value: status.id,
            });
          }
        });

      return statuses;
    })

    return filters;
  }
}

export {ResourceConfiguration}
