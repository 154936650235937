<template>
  <span :class="{'cursor-pointer select-none': true, 'bold': true}" @click.prevent="toggleStatus">
    <span class="text-red" v-if="status == 0">Disabled</span>
    <span class="text-green" v-else-if="status == 1">Enabled</span>
    <span class="text-orange" v-else>Suspended</span>
  </span>
</template>

<script>
import {CTooltip} from "@coreui/vue-pro/dist/esm/components/tooltip";
import {ref} from "vue";

export default {
  components: {CTooltip},
  props: {
    entry: {
      type: Object,
      required: true
    }
  },

  data()
  {
      return {
        status: this.entry.status
      }
  },

  watch:
  {
    entry()
    {
      this.status = this.entry.status
    }
  },

  methods: {
    toggleStatus()
    {
      this.$root.$fabric.axios.post('instances/'+this.entry.id, {
        status: this.status == 1 ? 0 : 1
      }).then(() =>
      {
        this.status = this.status ? 0 : 1;
      })
    }
  }
}
</script>
