<template>
  <div class="form-control">
    <CMultiSelect :label="attribute.label" :options="options" :multiple="handler.attributes.allow_multiple" @change="change" v-model="value"/>
  </div>
</template>

<script>
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";

export default {
  name: 'ResourceEditorTermAttribute',

  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    attribute: {
      type: Object,
      required: true
    },

    handler: {
      type: Object,
      required: true
    }
  },

  data()
  {
    let value = [];

    if (this.modelValue && JSON.parse(this.modelValue))
    {
      value = JSON.parse(this.modelValue);
    }
    else
    {
      value = [];
    }

    return {
      value: value,
      terms: [],
    }
  },

  mounted()
  {
      this.$root.$fabric.resources.fetchTerms(this.handler.attributes.resource_type, this.handler.attributes.taxonomy)
        .then((terms) =>
        {
          this.terms = terms;
        });
  },

  computed: {
    options: function ()
    {
      let options = [];

      for (let term of this.terms)
      {
        options.push({
          value: term.attributes.id,
          label: term.attributes.name,
          selected: this.value.includes(term.getId())
        });
      }

      return options;
    }
  },

  methods: {
      change: function (value)
      {
        this.value = value.map((item) => item.value);
      }
  },

  watch: {
      value: function (value)
      {
        this.$emit('update:modelValue', JSON.stringify(value));
      }
  },

  components: {
    CMultiSelect
  }
}
</script>
