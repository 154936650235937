<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Feed</span>
      </h4>

    </div>
  </div>
</template>

<script>


export default {

}
</script>
