<template>
  <CCard class="mt-[30px]">
    <CCardHeader>
      Captcha
    </CCardHeader>

    <CCardBody>
      <CaptchaImageToText v-if="type == 'ImageToTextTask'" v-model="solution" :payload="payload" />

      <CaptchaRecaptchaV2 v-else-if="type == 'RecaptchaV2TaskProxyless'" :payload="payload" v-model="solution" :id="id" />

      <template v-else>
        Unknown captcha type "{{type}}"
      </template>

      <CButton type="submit" class="mt-3" :disabled="solution === ''" v-if="type == 'ImageToTextTask'" @click="submit">Submit</CButton>
    </CCardBody>
  </CCard>
</template>

<script>
import {CCard, CCardBody, CCardHeader} from "@coreui/vue-pro/dist/esm/components/card";
import CaptchaImageToText from "@/views/components/CaptchaImageToText.vue";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";
import CaptchaRecaptchaV2 from "@/views/components/CaptchaRecaptchaV2.vue";

export default {
  components: {CaptchaRecaptchaV2, CButton, CaptchaImageToText, CCardBody, CCardHeader, CCard},
  props: {
    id: {
      type: Number,
      required: true,
    },

    type: {
      type: String,
    },

    payload: {
      type: Object
    }
  },

  data()
  {
    return {
      solution: '',
    }
  },

  methods: {
    submit()
    {
      this.$root.$fabric.axios.post('captcha/'+this.id, {
        solution: this.solution,
        status: 2
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(this.$emit('solved'));
    },
  },

  watch: {
    solution()
    {
      this.$emit('update:modelValue', this.solution);
    }
  }
}
</script>
