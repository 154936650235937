<script setup>
import { CContainer } from '@coreui/vue-pro'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import {useNotificationsStore} from "@/stores/notifications";
import {CToast, CToastBody, CToaster, CToastHeader} from "@coreui/vue-pro/dist/esm/components/toast";

const notifications = useNotificationsStore();

</script>

<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <CToaster class="p-3" placement="top-end">
        <CToast :color="toast.color" v-for="(toast, index) in notifications.notifications" visible :key="index">
          <CToastHeader closeButton>
            <span class="me-auto fw-bold">{{toast.title}}</span>
          </CToastHeader>
          <CToastBody v-if="toast.content">
            {{ toast.content }}
          </CToastBody>
        </CToast>
      </CToaster>

      <div class="body flex-grow-1">
          <router-view :key="$route.fullPath"/>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
