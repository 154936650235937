<template>
  <div class="content-section" :key="'content-section-'+section.id">
    <label class="fw-semibold">Content section: {{section.name}}</label>

    <ResourceEditorChildEditor
      :key="'content-section-children-'+section.id"
      :parent-id="this.resource.attributes.id"
      :child="{name: '', type: childType}"
      :child-type="childType"
      v-model="children"
      :show-add-new="false"
      @deleted="deleteChild"
      child-wrapper-class="form-control !p-[20px] !pb-0 !mb-[20px]"
      :filter="(child) => child.attributes.attributes._content_section == section.id">
      <template #header-title="props">
          <div class="align-middle inline-block">
            Content Block: {{getBlockName(props.resource.attributes.attributes._block_type)}}
          </div>
      </template>

      <template #afterChild="{resource}">
        <ResourceEditorBlockSelector :blocks="section.blocks"
                                     :resource="resource"
                                      @add-block="addNew"/>
      </template>
    </ResourceEditorChildEditor>

    <div class="mb-[20px]">
      <ResourceEditorBlockSelector :blocks="section.blocks"
                                   :default-show-selector="true"
                                   @add-block="addNew"/>
    </div>
  </div>
</template>

<script>
import ResourceEditorSections from "@/fabric/dashboard/resources/ResourceEditorSections.vue";
import ResourceEditorBlockSelector from "@/fabric/dashboard/resources/ResourceEditorBlockSelector.vue"
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem
} from "@coreui/vue-pro/dist/esm/components/accordion";
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";
import ResourceEditorChildEditor from "@/fabric/dashboard/resources/ResourceEditorChildEditor.vue";
import {FabricResource} from "@/fabric/resource";
import {CIcon} from "@coreui/icons-vue";
import {
  cilArrowThickSquareUp,
  cilArrowThickSquareDown, cilTrashX, cilChevronDoubleUpAlt, cisExpandUp, cisExpandDown
} from "@coreui/icons-pro";
import {cilArrowThickFromBottom, cilArrowThickFromTop, cilDelete} from "@coreui/icons";

export default {
  name: 'ResourceEditorContentSection',
  components: {
    CIcon,
    ResourceEditorChildEditor,
    CSpinner, CAccordionBody, CAccordionHeader, CAccordionItem, CAccordion, ResourceEditorSections,
    ResourceEditorBlockSelector
  },

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    section: {
      type: Object,
      required: true
    },

    childType: {
      type: String,
      required: true
    },

    resource: {
      type: Object,
      required: true
    }
  },

  data()
  {
      return {
        children: this.modelValue
          .sort((a, b) => a.attributes.sort_order - b.attributes.sort_order)
      }
  },

  methods: {
    cilArrowThickFromTop() {
      return cilArrowThickFromTop
    },
    cilArrowThickFromBottom() {
      return cilArrowThickFromBottom
    },
    cisExpandDown() {
      return cisExpandDown
    },
    cisExpandUp() {
      return cisExpandUp
    },
    cilChevronDoubleUpAlt() {
      return cilChevronDoubleUpAlt
    },
    cilTrashX() {
      return cilTrashX
    },
    cilArrowThickSquareDown() {
      return cilArrowThickSquareDown
    },
    cilArrowThickSquareUp() {
      return cilArrowThickSquareUp
    },
    cilDelete() {
      return cilDelete
    },
      addNew(type, afterResourceId)
      {
        console.log(afterResourceId);

        let attributes = {
          _content_section: this.section.id,
          _block_type: type
        };

        let resource = new FabricResource({
          parent_resource_id: this.resource.attributes.id,
          type: this.childType,
          attributes: attributes,
          taxonomies: {},
          sort_order: this.children.length + 1,
          created_at: new Date(),
        });

        let added = false;

        if (afterResourceId)
        {
          for (let child in this.children)
          {
            if (this.children[child].getId() == afterResourceId)
            {
              if (this.children[child].attributes.sort_order !== null)
              {
                resource.attributes.sort_order = this.children[child].attributes.sort_order + 1;
              }
              else
              {
                resource.attributes.sort_order = child + 1;
              }

              this.children.splice(
                parseInt(child) + 1,
                0,
                resource
              );

              added = true;
            }
          }

          if (added)
          {
            for (let child in this.children)
            {
              let contentSectionMatch = this.children[child].attributes.attributes._content_section == this.section.id;
              let equalPriority = this.children[child].attributes.sort_order >= resource.attributes.sort_order;
              let notItself = this.children[child].getId()!= resource.getId();

              if (contentSectionMatch && equalPriority && notItself)
              {
                this.children[child].attributes.sort_order++;
              }
            }
          }
        }

        console.log('added', added);

        if (!added)
        {
          this.children.push(resource);
        }

        console.log(this.children.length);
      },

    getBlockName(blockId)
    {
      let block = Object.values(this.section.blocks).find(block => block.id == blockId);

      if (!block)
      {
        return blockId;
      }

      return block.name;
    },

    deleteChild(childResource)
    {
      console.log('deleted in ResourceEditorContentSection', childResource);

      this.$emit('deleted', childResource);
    },

    moveUp(childResource)
    {
      let childIndex = this.children.indexOf(childResource);

      if (childIndex > 0)
      {
        this.children.splice(childIndex, 1);
        this.children.splice(childIndex - 1, 0, childResource);
      }

      for (let childIndex in this.children)
      {
        this.children[childIndex].attributes.sort_order = parseInt(childIndex) + 1;
      }
    },

    moveDown(childResource)
    {
      let childIndex = this.children.indexOf(childResource);

      if (childIndex < this.children.length - 1)
      {
        this.children.splice(childIndex, 1);
        this.children.splice(childIndex + 1, 0, childResource);
      }

      for (let childIndex in this.children)
      {
        this.children[childIndex].attributes.sort_order = parseInt(childIndex) + 1;
      }
    }
  },

  watch: {
    children: {
        handler: function (value)
        {
          this.$emit('update:modelValue', value);
        },
        deep: true
    },

    modelValue: {
      handler: function (value)
      {
        this.children = value;
      },
      deep: true
    }
  }
}
</script>
