<template>
  <div>
    <h3 class="mb-[20px]">{{label}}</h3>

    <template v-for="(group, index) in groups" :key="index">
      <MatcherSectionGroup v-model="groups[index]" :type="type" @deleted="deleteGroup(index)"></MatcherSectionGroup>
    </template>

    <CButton color="primary" @click.prevent="addGroup">
      New Group
    </CButton>
  </div>
</template>

<script>
import {CCard, CCardBody, CCardHeader} from "@coreui/vue-pro/dist/esm/components/card";
import MatcherSectionGroupCheck from "@/views/components/MatcherSectionGroupCheck.vue";
import MatcherSectionGroup from "@/views/components/MatcherSectionGroup.vue";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";

export default {
  name: 'MatcherSection',
  components: {CButton, MatcherSectionGroup, MatcherSectionGroupCheck, CCard, CCardHeader, CCardBody},

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    type: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    }
  },

  data()
  {
    return {
      selectedItems: [],
      groups: this.modelValue
    }
  },

  methods: {
    addGroup()
    {
      this.groups.push({
        name: '',
        type: '',
        priority_change: null,
        checks: []
      })
    },

    deleteGroup(index)
    {
      this.groups.splice(index, 1);
    }
  },

  watch: {
    modelValue(newVal) {
      this.groups = newVal;
    },

    groups: {
      deep: true,
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  }
}
</script>
