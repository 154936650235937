<template>
  <div class="flex gap-[20px] w-full flex-col">
    <template v-for="option in configuration">
      <div class="w-full flex-grow">
        <ConfigurationEditorText v-if="option.type === 'text'"
                                 v-model="data[option.id]"
                                 :label="option.name"
                                 :params="option.params" />

        <ConfigurationEditorTextarea v-if="option.type === 'textarea'"
                                 v-model="data[option.id]"
                                 :label="option.name"
                                 :params="option.params" />

        <ConfigurationEditorSelect v-if="option.type === 'select'"
                                   v-model="data[option.id]"
                                   :label="option.name"
                                   :params="option.params" />

        <ConfigurationEditorTags v-if="option.type === 'tags'"
                                   v-model="data[option.id]"
                                   :label="option.name"
                                   :params="option.params" />
      </div>
    </template>
  </div>
</template>

<script>
import ConfigurationEditorText from "@/views/components/ConfigurationEditorText.vue";
import ConfigurationEditorTextarea from "@/views/components/ConfigurationEditorTextarea.vue";
  import ConfigurationEditorSelect from "@/views/components/ConfigurationEditorSelect.vue";
import ConfigurationEditorTags from "@/views/components/ConfigurationEditorTags.vue";

  export default {
    name: 'ConfigurationEditor',
    components: {
      ConfigurationEditorTags,
      ConfigurationEditorSelect, ConfigurationEditorText, ConfigurationEditorTextarea},

    props: {
      configuration: {
        type: Object,
        required: true
      },

      modelValue: {
        type: Object,
        required: true
      }
    },

    data()
    {
      return {
        data: this.modelValue
      }
    },

    watch: {
      modelValue: {
        immediate: true,
        handler(newVal)
        {
          this.data = newVal;
        }
      },

      data: {
        deep: true,
        handler(newVal, oldVal)
        {
          this.$emit('update:modelValue', newVal);
        }
      }
    }
  }
</script>
