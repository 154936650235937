<template>
  <CContainer class="px-4 mt-4" fluid v-if="isInitialized">
    <CForm
      novalidate
      :validated="isFormValid"
      @submit="submit">
      <div class="flex gap-[20px] mb-[20px] flex-col md:flex-row">
        <div class="w-full border-0 flex justify-between align-baseline">
          <div style="max-width: 100%;">
            <CFormInput
              v-model="instance.name"
              type="text"
              size="lg"
              placeholder="Instance Name"
              aria-label="lg input"
              style="margin-right: auto; min-width: 600px; padding-left: 0 !important; min-height: 36px; height: 100%;"
              class="border-0 bg-transparent form-control-plaintext fw-bolder shadow-none px-2 pb-0 pt-0"/>
          </div>
        </div>

        <div class="w-full flex-shrink-0 flex rounded overflow-hidden shadow-md md:w-[300px]">
          <CButton :disabled="isSavingDisabled || !dataReady" as="button" type="submit" color="primary" class="min-w-[150px] flex-grow rounded-0" @click.prevent="submit">
            <CIcon :icon="cilSave" color="fff" class="mr-[5px]"/> Save
          </CButton>
        </div>
      </div>

      <CContainer v-if="!dataReady">
        <CRow class="justify-content-center h-[80vh] align-items-center">
          <CCol :md="6">
            <div class="clearfix text-center">
              <CSpinner/>
            </div>
          </CCol>
        </CRow>
      </CContainer>

      <div class="flex gap-[20px] flex-col" v-if="dataReady">
        <div class="flex-grow">
          <template v-if="dataReady">
            <CCard class="border-0 shadow-md">
              <CCardHeader class="text-uppercase fw-semibold">Details</CCardHeader>
              <CCardBody class="flex gap-[20px] flex-col">
                <CFormSelect
                  aria-label="Provider"
                  v-model="instance.provider"
                  id="provider"
                  :disabled="!isCreating"
                  floating-label="Provider"
                  :options="providerOptions">
                </CFormSelect>

                <CFormSelect
                  aria-label="Group"
                  v-model="instance.group_id"
                  id="group"
                  floating-label="Group"
                  :options="groupOptions">
                </CFormSelect>

                <CFormSelect
                  aria-label="Notification Priority"
                  v-model="instance.notification_priority"
                  id="notification_priority"
                  floating-label="Notification Priority"
                  :options="notificationPriorityOptions">
                </CFormSelect>

                <ConfigurationEditor v-model="instance.configuration"
                                     :configuration="provider.configuration"
                                     v-if="provider"></ConfigurationEditor>
              </CCardBody>
            </CCard>
          </template>
        </div>

        <MatcherSection type="validator" v-model="instance.matcher.validator" label="Validator"></MatcherSection>

        <MatcherSection type="seeker" v-model="instance.matcher.seeker" label="Seeker"></MatcherSection>
      </div>
    </CForm>
  </CContainer>
</template>

<script>
import {CForm, CFormInput, CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";
import {CCol, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CHeaderNav} from "@coreui/vue-pro/dist/esm/components/header";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";
import {CNavItem, CNavLink} from "@coreui/vue-pro/dist/esm/components/nav";
import {CContainer} from "@coreui/vue-pro";
import {useNotificationsStore} from "@/stores/notifications";
import router from '@/router'
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";
import { cilSave, cisViewCarousel } from '@coreui/icons-pro';
import {CCard, CCardBody, CCardHeader} from "@coreui/vue-pro/dist/esm/components/card";
import ConfigurationEditor from "@/views/components/ConfigurationEditor.vue";
import MatcherSection from "@/views/components/MatcherSection.vue";

export default {
  name: 'InstanceEditor',
  components: {
    MatcherSection,
    ConfigurationEditor,
    CFormSelect,
    CCardBody,
    CCardHeader,
    CCard, CSpinner, CCol, CRow, CButton, CNavLink, CNavItem, CHeaderNav, CFormInput, CForm, CContainer},

  data()
  {
    return {
      instance: null,
      dataReady: false,

      isSavingDisabled: false,
      isFormValid: null,
      notificationStore: useNotificationsStore(),

      router: router,

      isCreating: typeof this.$route.params.id === 'undefined',
      cilSave: cilSave,
      cisViewCarousel: cisViewCarousel,

      providers: [],
      groups: [],

      isInitialized: false,

      notificationPriorityOptions: [
        {value: 0, label: 'Disabled'},
        {value: 1, label: 'Normal (silent)'},
        {value: 2, label: 'Non-Stop (audio + 24/7)'},
        {value: 3, label: 'Emergency (audio + 24/7 + acknowledgement)'}
      ]
    };
  },

  computed: {
    providerOptions()
    {
      let options = [
        {
          value: '',
          label: 'Select Provider'
        }
      ];

      let providerOptions = this.providers.map((status) =>
      {
        return {
          value: status.id,
          label: status.name
        };
      });

      for (let option of providerOptions)
      {
        options.push(option);
      }

      return options;
    },

    groupOptions()
    {
      let options = [
        {
          value: '',
          label: 'Select Group'
        }
      ];

      let groupOptions = this.groups.map((group) =>
      {
        return {
          value: group.id,
          label: group.name
        };
      });

      for (let option of groupOptions)
      {
        options.push(option);
      }

      return options;
    },

    provider()
    {
      return this.providers.find((provider) => provider.id === this.instance.provider);
    }
  },

  mounted()
  {
    let awaitInstance;

    if (!this.isCreating)
    {
      awaitInstance = this.$root.$fabric.axios
        .get('instances/'+this.$route.params.id)
        .then(resource =>
        {
          this.instance = resource.data;
          this.dataReady = true;
        })
        .catch((error) =>
        {
          this.notificationStore.notifications.push({
            color: 'danger',
            title: error.message
          });

          router.push('/not-found');
        });
    }
    else
    {
      this.instance = {
        name: '',
        notification_priority: 0,
        provider: '',
        group_id: '',
        configuration: {},
        matcher: {
          validator: [],
          seeker: []
        }
      }

      this.dataReady = true;

      awaitInstance = new Promise((resolve, reject) =>
      {
        resolve(this.instance);
      })
    }

    let awaitProviders = this.$root.$fabric.axios
      .get('instance_handlers')
      .then(resource =>
      {
        this.providers = resource.data.results;
      });

    let awaitGroups = this.$root.$fabric.axios
      .get('instance_groups')
      .then(resource =>
      {
        this.groups = resource.data.results;
      });

    Promise.all([awaitInstance, awaitProviders, awaitGroups])
      .then(() => this.isInitialized = true);
  },

  methods: {
    submit()
    {
      let url = this.isCreating ? 'instances' : 'instances/' + this.$route.params.id;

      this.$root.$fabric.axios
        .post(url, {
          name: this.instance.name,
          group_id: this.instance.group_id,
          configuration: this.instance.configuration,
          provider: this.instance.provider,
          notification_priority: this.instance.notification_priority,
          matcher: this.instance.matcher
        })
        .then((response) =>
        {
          if (response.data.error)
          {
            this.notificationStore.notifications.push({
              title: response.data.error,
              color: 'error'
            });
          }
          else
          {
            this.notificationStore.notifications.push({
              title: 'Instance saved successfully!',
              color: 'success'
            });

            if (this.isCreating)
            {
              window.location.href = '/#/instance/' + response.data.id
            }
            else
            {
              window.location.reload();
            }
          }
        });
    }
  }
}
</script>
