<template>
  <CContainer class="px-4 mt-4" fluid v-if="isInitialized">
    <CForm
      novalidate
      :validated="isFormValid"
      @submit="submit">
      <div class="flex gap-[20px] mb-[20px]">
        <div class="w-full border-0 flex justify-between align-baseline">
          <div style="max-width: 100%;">
            <CFormInput
              v-model="resource.attributes.name"
              type="text"
              size="lg"
              :placeholder="configuration ? (configuration.name ?? 'Name') : 'Name'"
              aria-label="lg input"
              style="margin-right: auto; min-width: 600px; padding-left: 0 !important; min-height: 36px; height: 100%;"
              class="border-0 bg-transparent form-control-plaintext fw-bolder shadow-none px-2 pb-0 pt-0"/>
          </div>

          <CHeaderNav v-if="sections && sections.length > 1" class="gap-[20px] align-items-center bg-[#fff] !px-[20px] !py-[10px] rounded shadow-md">
            <template v-for="(section, i) in sections">
              <CNavItem class="px-1">
                <CNavLink href="#" :active="i == activeSection" @click="activeSection = i">
                  {{section.name}}
                </CNavLink>
              </CNavItem>
            </template>
          </CHeaderNav>
        </div>

        <div class="w-[300px] flex-shrink-0 flex rounded overflow-hidden shadow-md">
          <CButton v-if="url" :disabled="isSavingDisabled || !dataReady" as="button" type="submit" @click.prevent="preview" class="min-w-[150px] flex-grow bg-white rounded-0">
            <CIcon :icon="cisViewCarousel" color="fff" class="mr-[5px]"/> Preview
          </CButton>

          <CButton :disabled="isSavingDisabled || !dataReady" as="button" type="submit" color="primary" class="min-w-[150px] flex-grow rounded-0" @click.prevent="submit">
            <CIcon :icon="cilSave" color="fff" class="mr-[5px]"/> {{isCreating ? 'Publish' : 'Update' }}
          </CButton>
        </div>
      </div>

      <CContainer v-if="!dataReady">
        <CRow class="justify-content-center h-[80vh] align-items-center">
          <CCol :md="6">
            <div class="clearfix text-center">
              <CSpinner/>
            </div>
          </CCol>
        </CRow>
      </CContainer>

      <div class="flex gap-[20px]" v-if="dataReady">
        <div class="w-[calc(100%-300px)] flex-grow">
          <template v-if="dataReady">
            <ResourceEditorSections
              v-model="resource"
              :active-section="activeSection"
              :sections="sections"
              :taxonomies="taxonomies"
              :children="children"
              :childTypes="childTypes"
              :attributes="attributes"
              section-wrapper-class="card mb-[20px] border-0 border-0  rounded-[6px] shadow-md"
              section-header-class="card-header text-uppercase text-black !mb-0 fw-semibold !pl-[20px] !bg-white"
              section-body-class="section-body p-[20px] pb-[0] bg-white"
            >
              <template #field="props">
                <template v-for="child in props.childTypes" :key="'child-'+child.type">
                  <ResourceEditorChildEditor
                    @deleted="queueForDeletion"
                    :filter="filterDeletedResources"
                    :parent-id="props.resource.attributes.id"
                    v-if="props.field.type == 'child' && props.field.name == child.type"
                    :childType="child.type"
                    :child="child"
                    child-wrapper-class="form-control !p-[20px] !pb-0 mb-[20px]"
                    v-model="children">
                    <template #header-controls="{resource}">
                      <a v-if="resource.getId()" href="#" @click.prevent="$router.push({name: 'resource-editor', params: {resourceType: resource.getType(), resourceId: resource.getId()}})" v-c-tooltip="{content: 'Open in editor', placement: 'bottom'}" class="text-[#b6b9c1] hover:text-black">
                        <CIcon :name="cilLibrary()" size="lg" />
                      </a>
                    </template>
                  </ResourceEditorChildEditor>
                </template>

                <template v-for="contentSection in configuration.content_sections" :key="'content_section-'+contentSection.id">
                  <ResourceEditorContentSection
                    @deleted="queueForDeletion"
                    :resource="resource"
                    v-model="children"
                    v-if="props.field.type == 'content_section' && props.field.name == contentSection.id"
                    :section="contentSection"
                    :child-type="configuration.content_block_type"
                  ></ResourceEditorContentSection>
                </template>
              </template>
            </ResourceEditorSections>
          </template>
        </div>

        <div class="w-[300px] flex-shrink-0" v-if="!isCreating">
          <CCard class="border-0 shadow-md">
            <CCardHeader class="text-uppercase fw-semibold">Details</CCardHeader>
            <CCardBody>
              <CFormSelect
                aria-label="Select status"
                v-model="resource.attributes.status"
                id="status"
                floating-label="Status"
                class="mb-3"
                :disabled="statusOptions.length === 0"
                :options="statusOptions">
              </CFormSelect>

              <CFormInput v-if="configuration && configuration.content_block_type"
                          id="uri"
                          class="mb-3"
                          floating-label="URI"
                          type="text"
                          v-model="resource.attributes.slug"/>

              <CFormInput type="text"
                          :value="resource.getCreatedAt() ? resource.getCreatedAt().fromNow() : 'N/A'"
                          class="mb-3"
                          icon="cil-calendar"
                          floating-label="Created"
                          readonly
                          plain-text/>

              <CFormInput type="text"
                          :value="resource.getUpdatedAt() ? resource.getUpdatedAt().fromNow() : 'Never'"
                          floating-label="Updated"
                          readonly
                          plain-text
                          class=""/>
            </CCardBody>
          </CCard>
        </div>
      </div>
    </CForm>
  </CContainer>
</template>

<script>
import {CForm, CFormFloating, CFormInput, CFormLabel, CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";
import {CCol, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CIcon} from "@coreui/icons-vue";
import {CHeader, CHeaderNav, CHeaderToggler} from "@coreui/vue-pro/dist/esm/components/header";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";
import {CNavItem, CNavLink} from "@coreui/vue-pro/dist/esm/components/nav";
import {CCard, CCardBody, CCardHeader, CCardText, CCardTitle} from "@coreui/vue-pro/dist/esm/components/card";
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";
import {CContainer} from "@coreui/vue-pro";
import {useNotificationsStore} from "@/stores/notifications";
import ResourceEditorAttribute from "./ResourceEditorAttribute.vue";
import ResourceEditorTaxonomy from "./ResourceEditorTaxonomy.vue";
import router from '@/router'
import {FabricResource} from "@/fabric/resource";
import ResourceEditorSections from "./ResourceEditorSections.vue";
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";
import { cilSave, cisViewCarousel } from '@coreui/icons-pro';
import ResourceEditorChildEditor from "@/fabric/dashboard/resources/ResourceEditorChildEditor.vue";
import ResourceEditorContentSection from "@/fabric/dashboard/resources/ResourceEditorContentSection.vue";
import {cilFolderOpen, cilLibrary} from "@coreui/icons";

export default {
  name: 'ResourceEditor',

  data()
  {
    return {
      resource: null,
      children: [],

      isInitialized: false,
      isSavingDisabled: false,
      isFormValid: null,
      notificationStore: useNotificationsStore(),

      activeSection: 0,
      deleteQueue: [],

      statuses: [],
      attributes: null,
      taxonomies: null,
      childTypes: null,
      configuration: null,
      sections: null,

      router: router,

      isCreating: typeof this.$route.params.resourceId === 'undefined',
      cilSave: cilSave,
      cisViewCarousel: cisViewCarousel
    };
  },

  mounted()
  {
    let awaitResource;

    if (!this.isCreating)
    {
      awaitResource = this.$root.$fabric.resources
        .fetch(this.$route.params.resourceType, this.$route.params.resourceId)
        .then(resource =>
        {
          this.resource = resource;
          this.isInitialized = true;

          return resource;
        })
        .catch((error) =>
        {
          this.notificationStore.notifications.push({
            color: 'danger',
            title: error.message
          });

          router.push('/not-found');
        });
    }
    else
    {
      this.resource = new FabricResource({
        type: this.$route.params.resourceType,
        name: '',
        slug: '',
        attributes: {},
        taxonomies: {},
        children: []
      });

      this.isInitialized = true;

      awaitResource = new Promise((resolve, reject) =>
      {
        resolve(this.resource);
      })
    }

    this.$root.$fabric.resources
      .fetchStatuses(this.$route.params.resourceType)
      .then((statuses) => this.statuses = statuses);

    Promise.all([awaitResource])
      .then(() =>
      {
        let awaitAttributes = this.$root.$fabric.resources
          .fetchAttributes(this.$route.params.resourceType)
          .then((attributes) =>
          {
            for (let attribute of Object.values(attributes))
            {
              if (typeof this.resource.attributes.attributes[attribute.name] === 'undefined')
              {
                this.resource.attributes.attributes[attribute.name] = '';
              }
            }

            this.attributes = attributes;
          })

        let awaitTaxonomies = this.$root.$fabric.resources
          .fetchTaxonomies(this.$route.params.resourceType)
          .then((taxonomies) =>
          {
            for (let taxonomy of Object.values(taxonomies))
            {
              if (typeof this.resource.attributes.taxonomies[taxonomy.id] === 'undefined')
              {
                this.resource.attributes.taxonomies[taxonomy.id] = [];
              }
            }

            this.taxonomies = taxonomies;
          });

        let awaitChildConfiguration = this.$root.$fabric.resources
          .fetchChildren(this.$route.params.resourceType)
          .then((children) =>
          {
            this.childTypes = children;
          });

        let awaitConfiguration = this.$root.$fabric.resources
          .fetchEndpoint(this.$route.params.resourceType, 'configuration')
          .then((configuration) => this.configuration = configuration);

        Promise.all([awaitConfiguration, awaitAttributes, awaitTaxonomies, awaitChildConfiguration])
          .then(() =>
          {
            let queue = [];

            for (let childType of this.childTypes)
            {
              let childIsEdited = childType.type === this.configuration.content_block_type;

              for (let section of this.configuration.sections)
              {
                for (let group of section.groups)
                {
                  for (let selector of group.selectors)
                  {
                    if (selector.type === 'child')
                    {
                      childIsEdited = true;
                    }
                  }
                }
              }

              if (!childIsEdited)
              {
                continue;
              }

              if (!this.isCreating)
              {
                queue.push(
                  this.$root.$fabric.resources.find(childType.type, {parent_resource_id: this.resource.getId(), 'orderby': '+sort_order'})
                    .then((collection) =>
                    {
                      for (let resource of collection.getResults())
                      {
                        this.children.push(resource);
                      }
                    })
                );
              }
            }

            Promise.all(queue)
              .finally(() =>
              {
                let matchedFields = [];

                this.sections = this.configuration.sections.map((section) =>
                {
                  let groups = section.groups.map((group) =>
                  {
                    let fields = [];

                    for (let selector of group.selectors)
                    {
                      for (let attribute of Object.values(this.attributes))
                      {
                        let fieldData = {
                          type: 'attribute',
                          name: attribute.name
                        };

                        if (this.selectorMatchesAttribute(selector, attribute))
                        {
                          fields.push(fieldData);
                        }
                      }

                      for (let taxonomy of Object.values(this.taxonomies))
                      {
                        let fieldData = {
                          type: 'taxonomy',
                          name: taxonomy.id
                        };

                        if (this.selectorMatchesTaxonomy(selector, taxonomy))
                        {
                          fields.push(fieldData);
                        }
                      }

                      for (let child of Object.values(this.childTypes))
                      {
                        let fieldData = {
                          type: 'child',
                          name: child.type
                        };

                        if (this.selectorMatchesChild(selector, child))
                        {
                          fields.push(fieldData);
                        }
                      }

                      for (let contentSection of Object.values(this.configuration.content_sections))
                      {
                        let fieldData = {
                          type: 'content_section',
                          name: contentSection.id
                        };

                        if (contentSection.id == selector.selector && contentSection.dynamic_blocks)
                        {
                          fields.push(fieldData);
                        }
                      }
                    }

                    return {
                      name: group.name,
                      fields: fields
                    };
                  });

                  for (let groupId in groups)
                  {
                    groups[groupId].fields = groups[groupId].fields
                      .filter((value, index, arr) => arr.indexOf(value) === index)
                      .filter((field) => matchedFields.map((field) => field.type+":"+field.name).indexOf(field.type+":"+field.name) === -1);

                    matchedFields = matchedFields.concat(groups[groupId].fields);
                  }

                  section.groups = groups.filter((group) => group.fields.length);

                  return section;
                }).filter((section) => section.groups.length > 0);
              });
          })
      });
  },

  computed: {
    url()
    {
      return this.resource.attributes.url;
    },

    statusOptions()
    {
      return this.statuses.map((status) =>
      {
        return {
          value: status.id,
          label: status.name
        };
      });
    },

    dataReady()
    {
      return this.attributes != null && this.taxonomies != null && this.configuration != null && this.sections!= null;
    }
  },

  methods: {
    cilLibrary() {
      return cilLibrary
    },
    cilFolderOpen() {
      return cilFolderOpen
    },
    filterDeletedResources(resource)
    {
      return this.deleteQueue
        .map((resource) => resource.getId())
        .indexOf(resource.getId()) === -1;
    },

    queueForDeletion(childResource)
    {
      console.log('queued for deletion...',);
      console.error(childResource);

      if (childResource.getId())
      {
        this.deleteQueue.push(childResource);
      }

      for (let childIndex = 0; childIndex < this.children.length; childIndex++)
      {
        let match = this.children[childIndex] === childResource;

        if (match)
        {
          this.children.splice(childIndex, 1);
        }
      }
    },

    preview()
    {
      if (this.resource.attributes.url)
      {
        window.open(this.resource.attributes.url, '_blank');
      }
    },

    submit()
    {
      const form = event.currentTarget

      if (form.checkValidity() === false || !this.dataReady)
      {
        event.preventDefault()
        event.stopPropagation()
      }

      this.isSavingDisabled = true;

      let childSavePromises = [];

      // Validate children
      for (let child of this.children)
      {
        // make sure it isn't queued for deletion
        if (!child.getId() || this.deleteQueue.map((resource) => resource.getId()).indexOf(child.getId()) === -1)
        {
          childSavePromises.push(
            this.$root.$fabric.resources
              .save(child.getType(), child, {dryRun: true})
          )
        }
      }

      Promise.all(childSavePromises)
        .then(() =>
        {
          this.$root.$fabric.resources
            .save(this.$route.params.resourceType, this.resource, false)
            .then((response) =>
            {
              if (!response)
              {
                this.notificationStore.notifications.push({
                  title: 'Failed to save',
                  color: 'danger'
                });
              }
              else
              {
                if (response.id)
                {
                  Object.assign(this.resource, response);
                }

                let childUpdatePromises = [];

                // Delete children queued for deletion
                for (let childResource of this.deleteQueue)
                {
                  childUpdatePromises.push(
                    this.$root.$fabric.resources
                      .delete(childResource.getType(), childResource.getId())
                  )
                }

                // Save all children
                for (let childIndex in this.children)
                {
                  this.children[childIndex].attributes.parent_resource_id = this.resource.attributes.id;

                  // make sure it isn't queued for deletion
                  if (!this.children[childIndex].getId() || this.deleteQueue.map((resource) => resource.getId()).indexOf(this.children[childIndex].getId()) === -1)
                  {
                    childUpdatePromises.push(
                      this.$root.$fabric.resources
                        .save(this.children[childIndex].getType(), this.children[childIndex], {dryRun: false})
                        .then((resource) => this.children[childIndex] = resource)
                    )
                  }
                }

                Promise.all(childUpdatePromises).then(() =>
                {
                  this.notificationStore.notifications.push({
                    title: 'Your changes have been saved',
                    color: 'success'
                  });
                });
              }

              this.isCreating = false;
              this.isFormValid = null;
              this.isSavingDisabled = false;

              this.$router.push({
                name: 'resource-editor',
                params: {
                  resourceType: this.$route.params.resourceType,
                  resourceId: response.getId()
                }
              });
            })
            .catch((error) =>
            {
              console.error(error);

              if (error.response)
              {
                for (let errorMessage of error.response.data.errors)
                {
                  this.notificationStore.notifications.push({
                    title: errorMessage,
                    color: 'danger'
                  });
                }
              }
              else
              {
                this.notificationStore.notifications.push({
                  title: 'Failed to save due to technical error',
                  color: 'danger'
                });
              }

              this.isFormValid = null;
              this.isSavingDisabled = false;
            });
        })
        .catch((error) =>
        {
          console.error(error);

          for (let errorMessage of error.response.data.errors)
          {
            this.notificationStore.notifications.push({
              title: errorMessage,
              color: 'danger'
            });
          }

          this.isSavingDisabled = false;
        });
    },

    selectorMatchesTaxonomy(selector, taxonomy)
    {
      if ('taxonomy' != selector.type)
      {
        return false;
      }

      if (selector.selector == "*")
      {
        return true;
      }

      if (selector.selector == taxonomy.id)
      {
        return true;
      }

      return false;
    },

    selectorMatchesChild(selector, child)
    {
      if ('child' != selector.type)
      {
        return false;
      }

      if (selector.selector == "*")
      {
        return true;
      }

      if (selector.selector == child.type)
      {
        return true;
      }

      return false;
    },

      selectorMatchesAttribute(selector, attribute)
      {
          if ('attribute' != selector.type)
          {
              return false;
          }

          if (selector.selector == "*")
          {
              return true;
          }

          if (selector.selector == attribute.name)
          {
              return true;
          }

          return false;
      },
  },

  components: {
    ResourceEditorContentSection,
    ResourceEditorChildEditor,
    CSpinner,
    ResourceEditorSections,
    CForm,
    ResourceEditorTaxonomy,
    CFormFloating,
    ResourceEditorAttribute,
    CCol,
    CContainer,
    CFormSelect,
    CMultiSelect,
    CCard,
    CCardText,
    CCardTitle,
    CCardBody,
    CCardHeader,
    CNavLink,
    CNavItem,
    CHeaderNav,
    CButton,
    CHeaderToggler,
    CHeader,
    CIcon,
    CRow,
    CFormLabel,
    CFormInput
  },
}
</script>
