<template>
  <div class="form-control form-control-repeater !p-[20px]">
    <label class="mb-[10px] fw-bolder">{{attribute.label}}</label>

    <draggable
      v-model="values"
      handle=".grab-label"
      item-key="__repeater_id">
      <template #item="{element}">
        <div class="mb-[20px] entry">
          <label class="block mb-[5px] cursor-grab grab-label">
            Entry

            <a href="#" @click.prevent="remove(element.__repeater_id)" class="text-danger float-right">
              <CIcon :name="cilTrash()"/>
            </a>
          </label>

          <template v-for="attribute in attribute.handler.attributes.fields">
            <ResourceEditorAttribute  v-model="element[attribute.id]" :attribute="attribute" :type="attribute.type" class="mb-[20px]" :handler="attribute"/>
          </template>
        </div>
      </template>
    </draggable>

    <div class="text-right">
      <a href="#" @click.prevent="addNew" class="btn btn-xs btn-primary block">
        <CIcon :name="cilPlus()"/> Add New
      </a>
    </div>
  </div>
</template>

<script>
import ResourceEditorAttribute from "@/fabric/dashboard/resources/ResourceEditorAttribute.vue";
import {cilPlus, cilTrash} from "@coreui/icons";
import draggable from 'vuedraggable'

export default {
  name: 'ResourceEditorRepeaterAttribute',

  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    attribute: {
      type: Object,
      required: true
    }
  },

  data()
  {
    let value = this.modelValue;

    if (typeof value ==='string')
    {
      if (!value || JSON.parse(value).length === 0)
      {
        value = [];
      }
      else
      {
        value = JSON.parse(this.modelValue);
      }

      value = Object.values(value);
    }

    if (!Array.isArray(value))
    {
      value = [];
    }

    value = value.map(value =>
    {
      value.__repeater_id = (((1+Math.random())*0x10000)|0).toString(16);

      return value;
    });

    return {
      values: value,
      mediaResource: null,
      model: [],
    }
  },

  methods: {
    cilTrash() {
      return cilTrash
    },
    cilPlus() {
      return cilPlus
    },
    addNew()
    {
      let value = {
        __repeater_id: (((1+Math.random())*0x10000)|0).toString(16)
      };

      for (let attribute of Object.values(this.attribute.handler.attributes.fields))
      {
        value[attribute.id] = '';
      }

      this.values.push(value);
    },

    remove(id)
    {
      this.values = this.values.filter(item => item.__repeater_id!== id);
    }
  },

  watch: {
    values: {
      handler: function(value)
      {
        this.$emit('update:modelValue',
          value.map((item) => {
            const { __repeater_id, ...rest } = item;
            return rest;
          })
        );
      },
      deep: true
    }
  },

  components: {
    ResourceEditorAttribute,
    draggable
  }
}
</script>
