<template>
  <CContainer class="px-4 mt-4" fluid>
    <CForm
      novalidate
      :validated="isFormValid"
      @submit="submit">
      <div class="flex gap-[20px] mb-[20px]">
        <CHeader class="w-full border-0">
          <div style="max-width: 100%;">
            <CFormInput
              v-model="term.attributes.name"
              type="text"
              size="lg"
              placeholder="Name"
              aria-label="lg input"
              style="margin-right: auto; width: 400px; padding-left: 10px !important; min-height: 36px"
              class="border-0 bg-transparent form-control-plaintext fw-bolder shadow-none px-2 pb-0 pt-0"/>
          </div>
        </CHeader>

        <div class="w-[300px] flex-shrink-0 flex">
          <CButton :disabled="isSavingDisabled || !dataReady" as="button" type="submit" color="primary" class="min-w-[150px] grow" @click.prevent="submit">
            <CIcon :icon="cilSave()" color="fff" class="mr-[5px]"/> {{isCreating ? 'Publish' : 'Update' }}
          </CButton>
        </div>
      </div>

      <CContainer v-if="!dataReady">
        <CRow class="justify-content-center h-[80vh] align-items-center">
          <CCol :md="6">
            <div class="clearfix text-center">
              <CSpinner/>
            </div>
          </CCol>
        </CRow>
      </CContainer>

      <template v-if="dataReady && attributes.length">
        <div class="card mb-[20px] border-0 border-0  rounded-[6px] shadow-md">
          <div class="card-header text-uppercase text-black !mb-0 fw-semibold !pl-[20px] !bg-white">
            Details
          </div>
          <div class="section-body p-[20px] pb-[0] bg-white">
            <template v-for="attribute in attributes" :key="'attribute-'+attribute.name">
              <ResourceEditorAttribute
                :attribute="attribute"
                :type="attribute.handler.type"
                class="mb-[20px]"
                v-model="term.attributes.attributes[attribute.name]" />
            </template>
          </div>
        </div>
      </template>
    </CForm>
  </CContainer>
</template>

<script>


import {useNotificationsStore} from "@/stores/notifications";
import router from "@/router";
import {CHeader} from "@coreui/vue-pro/dist/esm/components/header";
import {CForm, CFormInput} from "@coreui/vue-pro/dist/esm/components/form";
import {CCol, CContainer, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";
import {cilSave} from "@coreui/icons-pro";
import ResourceEditorAttribute from "@/fabric/dashboard/resources/ResourceEditorAttribute.vue";
import {FabricTerm} from "@/fabric/resource";

export default {
  name: 'ResourceTermEditor',

  data()
  {
    return {
      term: {
        attributes: {
          name: ''
        }
      },

      attributes: null,

      isInitialized: false,
      isSavingDisabled: false,
      isFormValid: null,
      notificationStore: useNotificationsStore(),

      router: router,

      isCreating: typeof this.$route.params.termId === 'undefined',
    };
  },

  mounted()
  {
    let termPromise;

    if (!this.isCreating)
    {
      termPromise = this.$root['$fabric'].resources.fetchTerm(
        this.$route.params.resourceType,
        this.$route.params.taxonomyId,
        this.$route.params.termId,
      )
        .then((term) => this.term = term);
    }
    else
    {
      termPromise = new Promise((resolve, reject) =>
      {
        this.term = new FabricTerm({
          taxonomyId: this.$route.params.taxonomyId,
          resourceType: this.$route.params.resourceType,
          attributes: {}
        });

        resolve(this.term);
      });
    }

    termPromise.then(() =>
    {
      this.$root.$fabric.resources
        .fetchTaxonomyAttributes(this.$route.params.resourceType, this.$route.params.taxonomyId)
        .then((attributes) =>
        {
          console.log(this.term);

          for (let attribute of Object.values(attributes))
          {
            if (typeof this.term.attributes.attributes[attribute.name] === 'undefined')
            {
              this.term.attributes.attributes[attribute.name] = '';
            }
          }

          this.attributes = attributes;
        });
    });
  },

  computed: {
    dataReady()
    {
      return this.attributes!== null;
    }
  },

  methods: {
    cilSave() {
      return cilSave
    },

    submit()
    {
      this.$root['$fabric'].resources.saveTerm(
        this.$route.params.resourceType,
        this.$route.params.taxonomyId,
        this.term
      ).then((term) =>
      {
        this.$router.push({
          name: 'term-editor',
          params: {
            resourceType: this.$route.params.resourceType,
            taxonomyId: this.$route.params.taxonomyId,
            termId: term.getId()
          }
        });
      });
    }
  },

  components: {
    ResourceEditorAttribute,
    CSpinner,
    CCol,
    CRow,
    CButton,
    CFormInput,
    CContainer,
    CForm,
    CHeader

  },
}
</script>
