<template>
  <CCard class="border-0 shadow-md mb-[20px]">
    <CCardHeader class="text-uppercase fw-semibold">Group (<a href="#" @click.prevent="$emit('deleted')">Delete</a>)</CCardHeader>
    <CCardBody class="flex gap-[20px] flex-col">
      <template v-for="(check, index) in group.checks" :key="index">
        <MatcherSectionGroupCheck
          v-model="group.checks[index]"
          @deleted="deleteCheck(index)"
        ></MatcherSectionGroupCheck>
      </template>

      <CButton color="primary" class="w-[160px]" @click.prevent="addCheck">Add Check</CButton>

      <CFormSelect
        v-if="group.checks.length && type == 'seeker'"
        aria-label="Provider"
        v-model="group.priority_change"
        id="notification_priority"
        floating-label="Notification Priority"
        :options="notificationPriorityOptions">
      </CFormSelect>
    </CCardBody>
  </CCard>
</template>

<script>
import {CCard, CCardBody, CCardHeader} from "@coreui/vue-pro/dist/esm/components/card";
import MatcherSectionGroupCheck from "@/views/components/MatcherSectionGroupCheck.vue";
import {CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";

export default {
  name: 'MatcherSectionGroup',
  components: {CButton, CFormSelect, MatcherSectionGroupCheck, CCard, CCardHeader, CCardBody},

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    type: {
      type: String,
    }
  },

  data()
  {
    return {
      selectedItems: [],
      group: this.modelValue,
      notificationPriorityOptions: [
        {value: 0, label: 'Unchanged'},
        {value: 1, label: 'Normal (silent)'},
        {value: 2, label: 'Non-Stop (audio + 24/7)'},
        {value: 3, label: 'Emergency (audio + 24/7 + acknowledgement)'}
      ]
    }
  },

  methods: {
    addCheck()
    {
      this.group.checks.push({
        provider: '',
        configuration: {}
      })
    },

    deleteCheck(index)
    {
      this.group.checks.splice(index, 1);

      console.log('Check deleted');
    }
  },

  watch: {
    modelValue(newVal) {
      this.group = newVal;
    },

    group: {
      deep: true,
      handler(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  }
}
</script>
