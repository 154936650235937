import {isRef, toRef} from "vue";
import {JSX} from "vue/jsx-runtime";

interface FilterBuilderFilter
{
  id: string;
  order: number;
  name: string;
  wrapperClass: string;

  getId();
  getType() : string;
  getName(): string;
  getWrapperClass(): string;
}

class FilterBuilderFilterText implements FilterBuilderFilter
{
  id: string;
  order: number;
  name: string;
  wrapperClass: string;

    constructor(id: string, name: string, wrapperClass: string, order: number)
    {
        this.id = id;
        this.name = name;
        this.order = order;
        this.wrapperClass = wrapperClass;
    }

    getOrder(): number
    {
       return this.order;
    }

    setOrder(order: number): this
    {
       this.order = order;

       return this;
    }

    getId(): string
    {
        return this.id;
    }

    getName(): string
    {
      return this.name;
    }

    getWrapperClass(): string
    {
      return this.wrapperClass;
    }

    getType(): string
    {
      return 'text';
    }
}

class FilterBuilderFilterOptions implements FilterBuilderFilter
{
  id: string;
  order: number;
  name: string;
  wrapperClass: string;

  constructor(id: string, name: string, options: Array, wrapperClass: string, order: number)
  {
    this.id = id;
    this.name = name;
    this.order = order;
    this.wrapperClass = wrapperClass;
    this.options = options;
  }

  getOrder(): number
  {
    return this.order;
  }

  setOrder(order: number): this
  {
    this.order = order;

    return this;
  }

  getId(): string
  {
    return this.id;
  }

  getName(): string
  {
    return this.name;
  }

  getWrapperClass(): string
  {
    return this.wrapperClass;
  }

  getType(): string
  {
    return 'select';
  }

  getOptions(): ref<Array>
  {
    return toRef(this.options);
  }
}

class FilterBuilder
{
    /**
     * @type {FilterBuilderFilter[]}
     */
    filters: FilterBuilderFilter[] = [];

    getHighestPriority() : number
    {
      let order = 0;

      for (let filter of this.getFilters())
      {
        if (filter.getOrder() > order)
        {
          order = filter.getOrder();
        }
      }

      return order + 10;
    }

    /**
     * @param id
     * @param label
     * @param wrapperClass
     * @param order
     * @returns {FilterBuilderFilter}
     */
    addTextFilter(id: string, label: string, wrapperClass: string = "", order : int|null = null): FilterBuilderFilterText
    {
      if (order === null)
      {
        order = this.getHighestPriority();
      }

      const filter = new FilterBuilderFilterText(id, label, wrapperClass, order);

      this.filters.push(filter);

      return filter;
    }

  addSelectFilter(id: string, label: string, options: Array|(() => Array), wrapperClass: string = "", order : int|null = null): FilterBuilderFilterText
  {
    if (order === null)
    {
      order = this.getHighestPriority();
    }

    if (typeof options === 'function')
    {
      options = options();
    }

    const filter = new FilterBuilderFilterOptions(id, label, options, wrapperClass, order);

    this.filters.push(filter);

    return filter;
  }

    /**
     * @returns {FilterBuilderFilter[]}
     */
    getFilters(): FilterBuilderFilter[]
    {
        return this.filters.sort((a, b) => a.getOrder() - b.getOrder());
    }
}

export { FilterBuilder };
