<template>
  <div>
    <div class="text-black cursor-pointer form-control !px-[10px] !py-[10px]" @click.prevent="selectFile">
      <label class="mb-[10px] text-[#252b36a6]">{{attribute.label}}</label>
      <img v-if="mediaResource" :src="mediaResource.attributes.attachment_url" class="object-fit-contain object-left max-h-[200px]">
      <div v-else class="underline">Select Image</div>
    </div>

    <input type="file" name="file" ref="file" @change="onFileChange" hidden="hidden" class="hidden"/>
  </div>
</template>

<script>
import {FabricResource} from "@/fabric/resource";

export default {
  name: 'ResourceEditorMediaAttribute',

  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    attribute: {
      type: Object,
      required: true
    },

    resource: {
      type: Object,
      required: false
    },
  },

  data()
  {
    return {
      value: this.modelValue,
      mediaResource: null,
    }
  },

  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(value)
      {
        this.value = value;

        if (value)
        {
          this.$root.$fabric.resources.fetch('media', value)
            .then((media) =>
            {
              this.mediaResource = media;
            })
        }
        else
        {
          this.mediaResource = null;
        }
      }
    },

    value: {
      immediate: true,
      deep: true,
      handler(value)
      {
        this.$emit('update:modelValue', value);
      }
    }
  },

  methods: {
    selectFile()
    {
      this.$refs.file.click();
    },

    onFileChange(event)
    {
      if (event.target.files.length)
      {
        let resource = new FabricResource({
          type: 'media',
          parent_resource_id: this.resource ? this.resource.attributes.id : null,
        });

        this.$root.$fabric.resources.save('media', resource, {attachment: event.target.files[0]})
          .then((media) =>
          {
            // Set current value
            this.value = media.getId();
            this.mediaResource = media;
          })
      }
    }
  },

  mounted()
  {
    if (this.modelValue)
    {
      this.$root.$fabric.resources.fetch('media', this.modelValue)
       .then((media) =>
        {
          this.mediaResource = media;
        })
    }
  }
}
</script>
