<template>
  <div class="flex align-items-center cursor-pointer" @click.prevent="navigateTo">
    Job #{{entry.id}}
  </div>
</template>

<script>
import {CTooltip} from "@coreui/vue-pro/dist/esm/components/tooltip";
import {ref} from "vue";

export default {
  components: {CTooltip},
  props: {
    entry: {
      type: Object,
      required: true
    }
  },

  methods: {
    navigateTo()
    {
      this.$router.push({name: 'Job Logs', params: {
        jobId: this.entry.id,
        instanceId: this.entry.instance_id
      }})
    }
  }
}
</script>
