<template>
  <CFooter class="px-4">
    <div>
      <span class="ms-1"></span
      >
    </div>
    <div class="ms-auto">
      Powered by Fabric/Dashboard
    </div>
  </CFooter>
</template>
