import {ResourceConfiguration} from "@/fabric/dashboard/resources/ResourceConfiguration.ts";
import {assert} from "@vueuse/core";

export default (fabric) =>
{
  return {
    /**
     * @param {ResourceConfiguration[]}
     */
    resourceConfigurations: {},

    /**
     * Get resource list configuration by resource type.
     *
     * @param resourceType
     * @returns {ResourceConfiguration|*}
     */
    getResourceConfiguration(resourceType)
    {
      if (typeof this.resourceConfigurations[resourceType] !== 'undefined')
      {
        return this.resourceConfigurations[resourceType];
      }

      return new ResourceConfiguration(resourceType, fabric.axios);
    },

    /**
     * Set resource list configuration by resource type.
     *
     * @param resourceType
     * @param configuration
     * @returns {*}
     */
    setResourceConfiguration(resourceType, configuration)
    {
      assert(configuration instanceof ResourceConfiguration,
        'Configuration must be an instance of ResourceListConfiguration'
      );

      this.resourceConfigurations[resourceType] = configuration;

      return this;
    },

    fetchMenu(endpoint, params)
    {
      if (fabric.config.dashboard.menu !== null)
      {
        return new Promise((resolve, reject) =>
        {
          resolve(fabric.config.dashboard.menu);
        })
      }

      return fabric.axios({
        method: 'get',
        url: fabric.config.apiURL + fabric.config.menuEndpointName,
        params: params
      }).then(response =>
      {
        return response.data;
      });
    }
  }
}
