import {isRef, ref} from "vue";
import {JSX} from "vue/jsx-runtime";

interface TableBuilderColumn
{
    id: string;
    columnName: string;
    component?: (() => JSX.Element) | null; // Anonymous function returning JSX
    content?: ((data: any) => string | JSX.Element) | string; // Function or string
    sortable: boolean;
    size: string;
}

class TableBuilderColumn implements TableBuilderColumn
{
    id: string;
    columnName: string;
    order: number;

    component?: (() => JSX.Element) | null = null; // Anonymous function returning JSX
    content?: ((data: any) => string | JSX.Element) | string; // Function or string
    sortable: boolean = false;
    size: string = 'auto';

    constructor(id: string, columnName: string, order: number)
    {
        this.id = id;
        this.columnName = columnName;
        this.order = order;
    }

    getOrder(): number
    {
       return this.order;
    }

    setOrder(order: number): this
    {
       this.order = order;

       return this;
    }

    getId(): string
    {
        return this.id;
    }

    getName(): string
    {
        return this.columnName;
    }

    usingComponent(component: (() => JSX.Element) | null): this
    {
        this.component = component;
        return this;
    }

    getComponent(): (() => JSX.Element) | null
    {
        return this.component;
    }

    usingContentCallback(content: ((data: any) => string | JSX.Element) | string): this
    {
        this.content = content;

        return this;
    }

    getContent(data?: any): string | JSX.Element | undefined
    {
        return this.content;
    }

    setSize(size: string): this
    {
        this.size = size;
        return this;
    }

    getSize(): string
    {
        return this.size;
    }

    setSortable(sortable: boolean): this
    {
        this.sortable = sortable;
        return this;
    }

    isSortable(): boolean
    {
        return this.sortable;
    }
}

class TableBuilder
{
    // @ts-ignore
    private transformCallback: ((data: any) => (string | JSX.Element)) | string;

    private primaryColumnSize: string = '';

    private attributes: Object = ref({});

    /**
     * @type {TableBuilderColumn[]}
     */
    columns: TableBuilderColumn[] = [];

    /**
     * @param id
     * @param columnName
     * @param order
     * @returns {TableBuilderColumn}
     */
    addColumn(id: string, columnName: string, order : int|null): TableBuilderColumn
    {
        if (order === null)
        {
          for (let column of this.columns)
          {
            if (column.getOrder() > order)
            {
                order = column.getOrder();
            }
          }

          order = order + 10;
        }

        const column = new TableBuilderColumn(id, columnName, order);

        this.columns.push(column);

        return column;
    }

    /**
     * @returns {TableBuilderColumn[]}
     */
    getColumns(): TableBuilderColumn[]
    {
        return this.columns.sort((a, b) => a.getOrder() - b.getOrder());
    }

    setAttribute(key: string, value: any): this
    {
      this.attributes.value[key] = value;

      return this;
    }

    getData()
    {
      return this.attributes;
    }

    // @ts-ignore
    setTransformCallback(transform: ((data: any) => string | JSX.Element) | string): this
    {
        this.transformCallback = transform;

        return this;
    }

    // @ts-ignore
    getTransformCallback(): ((data: any) => string | JSX.Element) | string
    {
        return this.transformCallback;
    }

    setPrimaryColumnSize(size: string): this
    {
        this.primaryColumnSize = size;

        return this;
    }

    getPrimaryColumnSize(): string
    {
        return this.primaryColumnSize;
    }
}

export { TableBuilder, TableBuilderColumn };
