<template>
    <div class="relative ">
        <template v-if="expanded">
            <img :src="$root.assets_url+'/media/icons/toggle-expanded.svg'" class="cursor-pointer w-[30px] h-[14px] object-scale-down xl:float-right" @click.prevent="$emit('expand', '')"/>
        </template>

      <svg fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 14px;" class="cursor-pointer w-[30px] h-[14px] object-scale-down xl:float-right" @click.prevent="open = !open">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 5.25C0.7875 5.25 0 6.0375 0 7C0 7.9625 0.7875 8.75 1.75 8.75C2.7125 8.75 3.5 7.9625 3.5 7C3.5 6.0375 2.7125 5.25 1.75 5.25ZM1.75 0C0.7875 0 0 0.7875 0 1.75C0 2.7125 0.7875 3.5 1.75 3.5C2.7125 3.5 3.5 2.7125 3.5 1.75C3.5 0.7875 2.7125 0 1.75 0ZM1.75 10.5C0.7875 10.5 0 11.2875 0 12.25C0 13.2125 0.7875 14 1.75 14C2.7125 14 3.5 13.2125 3.5 12.25C3.5 11.2875 2.7125 10.5 1.75 10.5Z" fill="#435971"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 5.25C0.7875 5.25 0 6.0375 0 7C0 7.9625 0.7875 8.75 1.75 8.75C2.7125 8.75 3.5 7.9625 3.5 7C3.5 6.0375 2.7125 5.25 1.75 5.25ZM1.75 0C0.7875 0 0 0.7875 0 1.75C0 2.7125 0.7875 3.5 1.75 3.5C2.7125 3.5 3.5 2.7125 3.5 1.75C3.5 0.7875 2.7125 0 1.75 0ZM1.75 10.5C0.7875 10.5 0 11.2875 0 12.25C0 13.2125 0.7875 14 1.75 14C2.7125 14 3.5 13.2125 3.5 12.25C3.5 11.2875 2.7125 10.5 1.75 10.5Z" fill="white" fill-opacity="0.5"/>
      </svg>


      <div :class="{'min-w-[200px] p-[8px] bg-white rounded-md shadow-lg text-[13px] leading-[13px] normal-case flex flex-col gap-[6px] select-none absolute right-[50px] top-[20px] z-[60]': true}" v-if="open">
            <template v-for="action in actions" :key="action.name">
                <div v-if="isVisible(action)" class="group bg-[#F4F4F4] rounded-md p-[10px] cursor-pointer hover:underline" @click.prevent="callback(action)">
                    <span :class="{'text-[#494949]': !action.text, [action.text]: true}">{{action.name}}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        entry: {
            type: Object,
            required: true
        },

        expanded: {
            type: Boolean,
            default: false
        },

        actions: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            open: false
        }
    },

    mounted()
    {
        document.addEventListener("click", (e) =>
        {
            if (this.open && e.target !== this.$el && !this.$el.contains(e.target))
            {
                this.open = false;
            }
        });
    },

    methods: {
        isVisible(action)
        {
            if (typeof action.visible == 'undefined')
            {
                return true;
            }

            if (typeof action.visible == 'function')
            {
                console.log(action.visible(this.entry));

                return action.visible(this.entry);
            }

            if (typeof action.visible == 'boolean')
            {
                return action.visible;
            }

            return true;
        },

        callback(action)
        {
            this.open = false;

            action.callback(this.entry, this);
        }
    }
}
</script>
