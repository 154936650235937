<template>
  <div>
    <img :src="src" class="mb-[20px]">
    <CFormInput v-model="solution"/>
  </div>
</template>

<script>
import {CFormInput, CFormText} from "@coreui/vue-pro/dist/esm/components/form";

export default {
  components: {CFormInput, CFormText},
  props: {
    modelValue: {
      required: true,
    },

    payload: {
      required: true,
    },
  },

  data() {
    return {
      solution: '',
    }
  },

  computed: {
    src()
    {
      let body = this.payload.body;

      if (!body.startsWith('data:image'))
      {
        return 'data:image/jpeg;base64,'+body;
      }

      return body;
    }
  },

  watch: {
    solution() {
      this.$emit('update:modelValue', {
        code: this.solution
      });
    }
  }
}
</script>
