import moment from 'moment';

class FabricResource
{
  properties = [
    'name',
    'slug',
    'status',
    'attributes',
    'taxonomies',
    'children',
    'parent_resource_id',
    'sort_order'
  ];

  /**
   * Constructor
   *
   * @param resource
   */
  constructor(resource)
  {
    if (typeof resource.children !== 'undefined')
    {
      for (let childType in resource.children)
      {
        for (let child in resource.children[childType])
        {
          if (typeof resource.children[childType][child] === 'object')
          {
            resource.children[childType][child] = new FabricResource(resource.children[childType][child]);
          }
        }
      }
    }

    if (resource.id)
    {
      resource.identifier = resource.id;
    }
    else
    {
      // random temporary identifier used as key
      resource.identifier = (Math.random() + 1).toString(36).substring(7);
    }

    this.attributes = Object.assign({}, resource);
    this.originalAttributes = Object.assign({}, resource);
  }

  getType()
  {
    return this.attributes.type;
  }

  /**
   * Get resource id
   *
   * @returns {int}
   */
  getId()
  {
    if (typeof this.attributes.id === 'undefined')
    {
      return this.attributes.uuid;
    }

    return this.attributes.id;
  }

  /**
   * Get resource uuid
   *
   * @returns {string}
   */
  getUUID()
  {
    return this.attributes.uuid;
  }

  /**
   * Get resource name
   *
   * @returns {string}
   */
  getName()
  {
    return this.attributes.name;
  }

  /**
   * Set the resource name
   *
   * @param name
   */
  setName(name)
  {
    this.attributes.name = name;
  }

  /**
   * Get resource url
   *
   * @returns {string}
   */
  getURL()
  {
    return this.attributes.url;
  }

  /**
   * Get resource status
   *
   * @returns {string}
   */
  getStatus()
  {
    return this.attributes.status;
  }

  /**
   *
   * @returns {FabricResourceTaxonomyCollection}
   */
  getTaxonomies()
  {
    return new FabricResourceTaxonomyCollection(this.attributes.taxonomies);
  }

  /**
   *
   * @returns {FabricResourceAttributes}
   */
  getAttributes()
  {
    return new FabricResourceAttributes(this.attributes.attributes);
  }

  /**
   * Get resource created at date
   *
   * @returns {moment.Moment|null}
   */
  getCreatedAt()
  {
    if (!this.attributes.created_at)
    {
      return null;
    }

    return moment(this.attributes.created_at)
  }

  /**
   * Get resource updated at date
   *
   * @returns {moment.Moment|null}
   */
  getUpdatedAt()
  {
    if (!this.attributes.updated_at)
    {
      return null;
    }

    return moment(this.attributes.updated_at)
  }

  setAttribute(attribute, value)
  {
    this.attributes.attributes[attribute] = value;
  }

  getAttribute(attribute)
  {
    return this.attributes.attributes[attribute];
  }

  getChangedAttributes()
  {
    const changedAttributes = {};

    // Loop through each attribute in the current attributes
    for (const attribute in this.attributes)
    {
      // ignore certain attributes
      if (this.properties.indexOf(attribute) === -1)
      {
        continue;
      }

      const originalValue = this.originalAttributes[attribute];

      // Check if the attribute exists in the original attributes
      if (this.originalAttributes.hasOwnProperty(attribute))
      {
        // Check if the values are different
        if (this.attributes[attribute] !== originalValue)
        {
          changedAttributes[attribute] = this.attributes[attribute];
        }
      }
      else
      {
        // If the attribute doesn't exist originally, it's considered changed
        changedAttributes[attribute] = this.attributes[attribute];
      }
    }

    return changedAttributes;
  }

  getProperties()
  {
    const properties = {};

    // Loop through each attribute in the current attributes
    for (let attribute in this.attributes)
    {
      // ignore certain attributes
      if (this.properties.indexOf(attribute) === -1)
      {
        continue;
      }

      // Add the attribute to the properties array
      properties[attribute] = this.attributes[attribute];
    }

    return properties;
  }
}

class FabricTerm
{
  properties = [
    'name',
    'slug',
    'attributes',
  ];

  /**
   * Constructor
   *
   * @param resource
   */
  constructor(resource)
  {
    if (resource.id)
    {
      resource.identifier = resource.id;
    }
    else
    {
      // random temporary identifier used as key
      resource.identifier = (Math.random() + 1).toString(36).substring(7);
    }

    this.attributes = this.originalAttributes = resource;
  }

  /**
   * Get resource id
   *
   * @returns {int}
   */
  getId()
  {
    return this.attributes.id;
  }

  /**
   * Get resource name
   *
   * @returns {string}
   */
  getName()
  {
    return this.attributes.name;
  }

  /**
   * Set the resource name
   *
   * @param name
   */
  setName(name)
  {
    this.attributes.name = name;
  }

  getChangedAttributes()
  {
    const changedAttributes = {};

    // Loop through each attribute in the current attributes
    for (const attribute in this.attributes)
    {
      // ignore certain attributes
      if (this.properties.indexOf(attribute) === -1)
      {
        continue;
      }

      const originalValue = this.originalAttributes[attribute];

      // Check if the attribute exists in the original attributes
      if (this.originalAttributes.hasOwnProperty(attribute))
      {
        // Check if the values are different
        if (this.attributes[attribute] !== originalValue)
        {
          changedAttributes[attribute] = this.attributes[attribute];
        }
      }
      else
      {
        // If the attribute doesn't exist originally, it's considered changed
        changedAttributes[attribute] = this.attributes[attribute];
      }
    }

    return changedAttributes;
  }

  getProperties()
  {
    const properties = {};

    // Loop through each attribute in the current attributes
    for (let attribute in this.attributes)
    {
      // ignore certain attributes
      if (this.properties.indexOf(attribute) === -1)
      {
        continue;
      }

      // Add the attribute to the properties array
      properties[attribute] = this.attributes[attribute];
    }

    return properties;
  }
}

class FabricResourceTaxonomyCollection
{
  constructor(taxonomies)
  {
    this.taxonomies = taxonomies;
  }
}

class FabricResourceAttributes
{
  constructor(attributes)
  {
    this.attributes = attributes;
  }
}

class FabricResourceCollection {
  constructor(response)
  {
    this.response = response;
  }

  getTotal()
  {
    return this.response.total;
  }

  getCount()
  {
    return this.response.results.length;
  }

  getResults()
  {
    return this.response.results.map(result => new FabricResource(result));
  }
}

export {
  FabricResource,
  FabricResourceCollection,
  FabricTerm
}
