<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Instances</span>
        <router-link to="instance/new" class="btn btn-primary btn-xs float-right inline-block">New Instance</router-link>
      </h4>

      <Collection :endpoint="this.$root.$fabric.config.apiURL + 'instances'"
                  :table="InstanceTableConfiguration"
                  :state-manager="stateManager"
                  order-by="-status,-is_starred,-latest_hit_at"
                  :actions="actions"
                  :state-manager-interval="5"
      ></Collection>

      <div class="text-muted flex gap-[20px] text-right text-sm">
        <a @click.prevent="sendTestNotification(1)" href="#" class="text-muted text-decoration-none">Test Standard Notification</a>
        <a @click.prevent="sendTestNotification(2)" href="#" class="text-muted text-decoration-none">Test Level 2 Notification</a>
        <a @click.prevent="sendTestNotification(3)" href="#" class="text-muted text-decoration-none">Test Emergency Notification</a>
      </div>
    </div>
  </div>
</template>

<script>
import {CCol, CContainer, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CChart} from "@coreui/vue-chartjs";
import {CWidgetStatsA, CWidgetStatsB} from "@coreui/vue-pro/dist/esm/components/widgets";
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/vue-pro/dist/esm/components/dropdown";
import Collection from "@/fabric/ui/Collection.vue";
import {TableBuilder} from "@/fabric/UI";
import {ref} from "vue";

export default {
  computed: {
    InstanceTableConfiguration()
    {
      let tableBuilder = new TableBuilder();

      // @ts-ignore
      tableBuilder
        .addColumn('name', 'Name')
        .setSize('w-[600px]')
        .usingComponent(() => import('@/resources/instance/InstanceName.vue'));

    if (false)
    {
      tableBuilder
        .addColumn('provider', 'Provider')
        .setSize('w-[200px]')
        .usingContentCallback((resource, state) =>
        {
          if (state && state[resource.id] && state[resource.id].provider)
          {
            return state[resource.id].provider.name;
          }

          return resource.provider;
        });
    }

      tableBuilder
        .addColumn('heartbeat', 'State')
        .setSize('w-[180px]')
        .usingComponent(() => import('@/resources/instance/InstanceHeartbeat.vue'));

      if (false)
      {
        tableBuilder
          .addColumn('uptime', 'Uptime')
          .setSize('w-[70px]')
          .usingContentCallback((resource, state) =>
          {
            if (state && state[resource.id] && state[resource.id].provider)
            {
              if (typeof state[resource.id] === 'undefined' || typeof state[resource.id].job === 'undefined')
              {
                return '';
              }

              let uptime = state[resource.id].job.uptime;

              if (!uptime)
              {
                uptime = 0;
              }

              // convert uptime in seconds to hh:mm:ss format
              let seconds = Math.floor(uptime % 60);
              let minutes = Math.floor((uptime / 60) % 60);
              let hours = Math.floor(uptime / 3600);

              return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }

            return '00:00:00';
          });
      }

      tableBuilder
        .addColumn('success_rate', 'Success Rate')
        .setSize('w-[95px]')
        .usingContentCallback((resource, state) =>
        {
          if (state && state[resource.id] && state[resource.id].provider)
          {
            if (typeof state[resource.id] === 'undefined' || typeof state[resource.id].job === 'undefined')
            {
              return '';
            }

            let successRate = state[resource.id].job.success_rate;

            if (successRate === null)
            {
              return 'N/A';
            }

            return `${successRate.toFixed(2)}%`;
          }

          return 'N/A';
        });

      tableBuilder
        .addColumn('hits', 'Hits')
        .setSize('w-[100px]')
        .usingComponent(() => import('@/resources/instance/InstanceHits.vue'));

      tableBuilder
        .addColumn('execution_interval', 'Interval')
        .setSize('w-[100px]')
        .usingContentCallback((resource) =>
        {
          let rand = '';

          if (!resource.configuration._interval)
          {
            return 'N/A';
          }

          if (resource.configuration._interval.indexOf(',') > -1)
          {
            let intervals = resource.configuration._interval.split(',');

            resource.configuration._interval = intervals[0];

            rand = '~';
          }

          let minutes = Math.floor(resource.configuration._interval / 60);
          let seconds = resource.configuration._interval % 60;

          return rand+(minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        });

      if (false)
      {
        tableBuilder
          .addColumn('actual_interval', 'Actual Interval')
          .setSize('w-[150px]')
          .usingContentCallback(function (resource, state)
          {
            if (state && state[resource.id] && state[resource.id].job)
            {
              let actual_interval = parseInt(state[resource.id].job.actual_interval);
              let minutes = Math.floor(actual_interval / 60);
              let seconds = actual_interval % 60;

              return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            }

            return 'N/A';
          });
      }

      tableBuilder
        .addColumn('status', 'Status')
        .setSize('w-[100px]')
        .usingComponent(() => import('@/resources/instance/InstanceStatus.vue'));

      return tableBuilder;
    }
  },
  components: {
    Collection,
    CWidgetStatsB,
    CDropdownItem, CDropdownMenu, CDropdownToggle, CDropdown, CWidgetStatsA, CChart, CCol, CRow, CContainer},

  data()
  {
    let providers = ref([]);

    return {
      providers: providers,

      stateManager: function (ids)
      {
        return new Promise((resolve) =>
        {
          this.$root.$fabric.axios.get('instances', {
            params: {
              id: ids
            }
          })
            .then((response) => response.data.results)
            .then((instances) => {
              this.$root.$fabric.axios.get('user/instance_states', {
                params: {
                  instance_id_in: ids
                }
              })
                .then((response) => response.data.results)
                .then((states) =>
                {
                  console.log(states);

                  this.$root.$fabric.axios.get('jobs', {
                    params: {
                      instance_id_in: ids,
                      parent_job_id: null,
                    }
                  })
                    .then((response) => response.data.results)
                    .then((jobs) =>
                    {
                      let result = {};

                      instances.forEach((instance) =>
                      {
                        result[instance.id] = {
                          ...instance,

                          state: states
                            .findLast((state) => state.instance_id === instance.id),

                          job: jobs
                            .findLast((job) => job.instance_id === instance.id),
                          provider: providers.value ? providers.value.find((provider) => provider.id == instance.provider) : {}
                        };
                      });

                      resolve(result);
                    })
                })
            });
        });
      },

      actions: [
        {
          name: 'Edit',
          callback: (entry, content) =>
          {
            this.$router.push({name: 'Instance Editor', params: {id: entry.id}})
          }
        },

        {
          name: 'Star',
          visible: (entry) => !entry.is_starred,
          icon: 'https://img.icons8.com/fluency/24/star--v1.png',
          text: 'text-[#494949]',
          callback: (entry, content) =>
          {
            this.$root.$fabric.axios.post('instances/' + entry.id, {
              starred: 1
            });

            entry.is_starred = 1;

            this.$emit('update:modelValue', entry);
          }
        },

        {
          name: 'Un-star',
          visible: (entry) => entry.is_starred,
          icon: 'https://img.icons8.com/fluency/24/star--v1.png',
          text: 'text-[#494949]',
          callback: (entry, content) =>
          {
            this.$root.$fabric.axios.post('instances/' + entry.id, {
              starred: 0
            });

            entry.is_starred = 0;

            this.$emit('update:modelValue', entry);
          }
        },

        {
          name: 'Delete',
          icon: 'stop-product-sync.svg',
          text: 'text-[#D15B47]',
          callback: (entry) =>
          {
            if (confirm('Are you sure you want to delete this instance?'))
            {
              this.$root.$fabric.axios.delete('instances/' + entry.id)
                .then(() => window.location.reload());
            }
          }
        },
      ]
    }
  },

  methods: {
    sendTestNotification(priority)
    {
      this.$root.$fabric.axios.post('debug', {
        action: 'test_notification',
        priority: priority
      });
    }
  },

  mounted()
  {
    this.$root.$fabric.axios.get('instance_handlers')
     .then((response) => this.providers = response.data.results);
  }
}
</script>
