<template>
  <div :class="{'cursor-pointer select-none min-h-[24px]': true, 'bold': true}">
    <template v-if="currentHits === null">
      <span class="text-orange">Loading</span>
    </template>

    <template v-else>
      <span class="text-green" @click.prevent="navigateTo">{{currentHits}}</span>

      <template v-if="hits != currentHits && !justSeen">
        <span class="bg-green text-white p-[1px] pr-[7px] rounded-1 ml-[10px]" @click.prevent="markSeen">
          + {{Math.abs(currentHits - hits)}}
        </span>
      </template>
    </template>
  </div>
</template>

<script>
import {CTooltip} from "@coreui/vue-pro/dist/esm/components/tooltip";
import {ref} from "vue";

export default {
  components: {CTooltip},
  props: {
    entry: {
      type: Object,
      required: true
    },

    state: {
      type: [Object, Array]
    }
  },

  data()
  {
      return {
        justSeen: false
      }
  },

  computed: {
    hits()
    {
      if (!this.state)
      {
        return 0;
      }

      if (typeof this.state[this.entry.id] === 'undefined')
      {
        return 0;
      }

      if (typeof this.state[this.entry.id].state === 'undefined')
      {
        return 0;
      }

      return this.state[this.entry.id].state.viewed_hit_count;
    },

    currentHits()
    {
        if (!this.state)
        {
          return this.hits;
        }

        if (typeof this.state[this.entry.id] === 'undefined')
        {
          return this.hits;
        }

        return this.state[this.entry.id].hits;
    }
  },

  watch: {
    state: {
      deep: true,
      handler(newVal, oldVal)
      {
        this.justSeen = false;
      }
    }
  },

  methods: {
    navigateTo()
    {
      this.$root.$fabric.axios.post('user/instance_states/' + this.entry.id, {
        viewed_hit_count: this.currentHits
      });

      this.$router.push({name: 'Hits', params: {instanceId: this.entry.id}});
    },

    markSeen()
    {
      this.justSeen = true;

      this.$root.$fabric.axios.post('user/instance_states/' + this.entry.id, {
        viewed_hit_count: this.currentHits
      });
    }
  }
}
</script>
