<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Jobs</span>
      </h4>

      <Collection :endpoint="this.$root.$fabric.config.apiURL + 'logs?job_id='+this.$route.params.jobId"
                  :actions="actions"
                  order-by="-id"
                  :sync-interval="1"
                  :table="TableConfiguration"></Collection>
    </div>
  </div>
</template>

<script>
import {CCol, CContainer, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CChart} from "@coreui/vue-chartjs";
import {CWidgetStatsA, CWidgetStatsB} from "@coreui/vue-pro/dist/esm/components/widgets";
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/vue-pro/dist/esm/components/dropdown";
import Collection from "@/fabric/ui/Collection.vue";
import {TableBuilder} from "@/fabric/UI";
import {FilterBuilder} from "@/fabric/ui/FilterBuilder";
import ResourceListFilters from "@/fabric/dashboard/resources/ResourceListFilters.vue";
import CollectionFilters from "@/views/components/CollectionFilters.vue";

export default {
  computed: {
    filters()
    {
    let filters = new FilterBuilder();

      filters.addTextFilter('keyword', 'Keyword');

      return filters;
    },

    actions()
    {
      return []
    },

    TableConfiguration()
    {
      let tableBuilder = new TableBuilder();

      // @ts-ignore
      tableBuilder
        .addColumn('message', 'Message')
        .setSize('w-[900px]')
        .usingContentCallback(function (entry)
        {
          return entry.message;
        });

      tableBuilder
        .addColumn('date', 'Date')
        .setSize('w-[200px]')
        .usingContentCallback(function (entry)
        {
          return entry.created_at;
        });


      return tableBuilder;
    },
  },
  components: {
    CollectionFilters,
    ResourceListFilters,
    Collection,
    CWidgetStatsB,
    CDropdownItem, CDropdownMenu, CDropdownToggle, CDropdown, CWidgetStatsA, CChart, CCol, CRow, CContainer},

  data()
  {
    return {

    }
  },

  mounted()
  {

  }
}
</script>
