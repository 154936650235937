<template>
  <div :class="{'text-[13px] align-middle normal-case': true, [column.size]: true, [_row_class(column)]: true, 'max-w-full': true, '!flex-grow': grow}">
    <template v-if="component">
      <component
          :is="component"
          :entry="entry"
          :state="state"
          v-if="column.component"
          @expand="expand"
          :expanded="expanded"
          @hide="$emit('hide', column)"
          v-bind="{actions: actions, ...data.value}"/>
    </template>

    <span v-html="callback_content" v-else></span>
  </div>
</template>

<script>

import {isRef} from "vue";

export default {
    props: {
        column: Object,
        modelValue: Object,

      state: {
        type: [Array, Object]
      },

        actions: {
            type: Array,
            default: []
        },

        expanded: {
            type: Boolean,
            default: false
        },

        data: {
          type: Object,
          default: {},
          required: false
        },

        grow: {
            type: Boolean,
            default: false
        }
    },

    data()
    {
        return {
            selected: false,
            entry: this.modelValue,
            component: null
        };
    },

    async mounted()
    {
      if (this.column.component)
      {
        this.componentContent = (this.column.component)(this.entry).then(component =>
        {
          this.component = component.default;
        });
      }
    },

    watch: {
        modelValue(value)
        {
            this.entry = value;
        }
    },

    methods: {
        _row_class(column)
        {
            let classes = [];

            if (!column.adaptable)
            {
                classes.push('xl:flex-shrink-0 xl:flex-grow-0');
            }
            else
            {
                classes.push('xl:flex-grow');
            }

            classes.push('flex-shrink-0 flex-grow-0');

            return classes.join(' ');
        },

        expand(component)
        {
            this.$emit('expand', component);
        }
    },

  computed: {
      callback_content()
      {
          if (this.column.content)
          {
              let value = this.column.content(this.entry, this.state);

              if (isRef(value))
              {
                return value;
              }

              return value;
          }
          else
          {
              return this.entry;
          }
      }
  }
}
</script>
