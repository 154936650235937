<template>
  <div v-if="pages > 1" class="pl-[10px]">
      <div class="flex gap-[10px] flex-wrap">
          <a v-for="page in pages" :class="{'inline-block p-[5px] w-[15px] text-center bg-white cursor-pointer text-[#000] no-underline': true, [shadow]: true, [rounding]: true, '!underline': value == page}" @click.prevent="value = page">
              {{page}}
          </a>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        modelValue: Number,
        results: Number,
        total: Number,
        resultsPerPage: [Number, String],

        rounding: String,
        shadow: String
    },

    data()
    {
        return {
            value: this.modelValue,
        }
    },

    computed: {
        pages()
        {
            return Math.max(
                Math.ceil(this.total / parseInt(this.resultsPerPage)),
                1
            );
        },
    },

    watch: {
        modelValue()
        {
            this.value = this.modelValue;
        },

        value()
        {
            this.$emit('update:modelValue', this.value);
            this.$emit('change', this.value)
        }
    },
}
</script>
