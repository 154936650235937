<template>
  <div class="form-control">
    <CFormSwitch :label="attribute.label" :id="attribute.id" v-model="value"/>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import {CDatePicker} from "@coreui/vue-pro/dist/esm/components/date-picker";
import {CFormLabel, CFormSwitch} from "@coreui/vue-pro/dist/esm/components/form";

export default {
  name: 'resource-editor-boolean-attribute',

  props: {
    modelValue: {
      type: [String, Date],
      required: true
    },

    attribute: {
      type: Object,
      required: true
    }
  },

  data()
  {
      return {
        value: this.modelValue,
        format,
        parse
      }
  },

  watch: {
    modelValue: {
      handler: function (value)
      {
        this.value = value;
      },

      deep: true
    },

    value: {
      handler: function (value)
      {
        this.$emit('update:modelValue', value);
      },

      deep: true
    }
  },

  components: {
    CFormSwitch,
    CFormLabel,
    CDatePicker
  }
}
</script>
