<template>
    <div :class="_column_classes(column)"
         @click.prevent="select">
        {{column.name}}
    </div>
</template>

<script>
export default {
    props: {
        column: Object,
        activeColumn: String,
    },

    data()
    {
      return {
        selected: false,
      };
    },

    methods: {
        select: function ()
        {
            if (this.column.sortable)
            {
                this.$emit('select', this.column.id);
            }
        },

        _column_classes(column)
        {
            return {
                // set size attribute
                [column.size]: true,

                'cursor-pointer': column.sortable,

                // prevent adaptable columns from shrinking
                'flex-shrink-0 flex-grow-0': true,

                'xl:flex-grow': column.adaptable,

                // underline if sorting by column
                'underline': this.activeColumn == column.id,

                // add custom classes
                [column.class]: true
            };
        }
    }
}
</script>