<template>
  <CFormSelect :floating-label="label"
              type="text"
              class="w-full"
               :options="options"
              v-model="data"/>
</template>

<script>
import {CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";

  export default {
    name: 'ConfigurationEditorSelect',
    components: {CFormSelect},

    props: {
      label: {
        type: String,
        required: true
      },

      params: {
        type: Object,
        required: true
      },

      modelValue: {
        type: Object,
        required: true
      }
    },

    data()
    {
      return {
        data: this.modelValue
      }
    },

    mounted()
    {
      // default to first option if no value is provided
      if (this.data == "")
      {
        this.data = this.params.options[0];
      }
    },

    computed: {
      options()
      {
        let options = [];

        if (Array.isArray(this.params.options))
        {
          for (let item of this.params.options)
          {
            options.push({
              label: item,
              value: item
            });
          }
        }
        else
        {
          for (let key in this.params.options)
          {
            options.push({
              label: this.params.options[key],
              value: key
            });
          }
        }

        return options;
      }
    },

    watch: {
      modelValue: {
        immediate: true,
        handler(newVal)
        {
          this.data = newVal;
        }
      },

      data: {
        deep: true,
        handler(newVal, oldVal)
        {
          this.$emit('update:modelValue', newVal);
        }
      }
    }
  }
</script>
