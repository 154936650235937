<template>
  <CFormTextarea :floating-label="label"
                 class="w-full min-h-[200px]"
                  v-model="data"/>
</template>

<script>
import {CFormInput, CFormTextarea} from "@coreui/vue-pro/dist/esm/components/form";

  export default {
    name: 'ConfigurationEditorTextarea',
    components: {CFormTextarea, CFormInput},

    props: {
      label: {
        type: String,
        required: true
      },

      params: {
        type: Object,
        required: true
      },

      modelValue: {
        type: Object,
        required: true
      }
    },

    data()
    {
      return {
        data: this.modelValue
      }
    },

    watch: {
      modelValue: {
        immediate: true,
        handler(newVal)
        {
          this.data = newVal;
        }
      },

      data: {
        deep: true,
        handler(newVal, oldVal)
        {
          this.$emit('update:modelValue', newVal);
        }
      }
    }
  }
</script>
