<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Jobs</span>
      </h4>

      <Collection :endpoint="this.$root.$fabric.config.apiURL + 'jobs?instance_id='+this.$route.params.instanceId"
                  :actions="actions"
                  order-by="-id"
                  :table="TableConfiguration"></Collection>
    </div>
  </div>
</template>

<script>
import {CCol, CContainer, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CChart} from "@coreui/vue-chartjs";
import {CWidgetStatsA, CWidgetStatsB} from "@coreui/vue-pro/dist/esm/components/widgets";
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/vue-pro/dist/esm/components/dropdown";
import Collection from "@/fabric/ui/Collection.vue";
import {TableBuilder} from "@/fabric/UI";
import {FilterBuilder} from "@/fabric/ui/FilterBuilder";
import ResourceListFilters from "@/fabric/dashboard/resources/ResourceListFilters.vue";
import CollectionFilters from "@/views/components/CollectionFilters.vue";
import VuePureLightbox from 'vue-pure-lightbox'

export default {
  computed: {
    filters()
    {
    let filters = new FilterBuilder();

      filters.addTextFilter('keyword', 'Keyword');

      return filters;
    },

    actions()
    {
      return [
        {
          name: 'Stop',
          text: 'text-[#D15B47]',
          callback: (entry) =>
          {
            if (confirm('Are you sure you want to stop this jobs?'))
            {
              this.$root.$fabric.axios.post('jobs/' + entry.id, {
                active: '0',
                status: 'stopped by web UI'
              })
                .then(() => window.location.reload());
            }
          }
        },

        {
          name: 'Logs',
          callback: (entry, content) =>
          {
            this.$router.push({name: 'Job Logs', params: {jobId: entry.id, instanceId: this.$route.params.instanceId }})
          }
        },
      ]
    },

    TableConfiguration()
    {
      let tableBuilder = new TableBuilder();

      // @ts-ignore
      tableBuilder
        .addColumn('name', 'Name')
        .setSize('w-[600px]')
        .usingComponent(() => import('@/resources/job/JobName.vue'));

      tableBuilder
        .addColumn('status', 'Status Message')
        .setSize('w-[200px]')
        .usingContentCallback(function (entry)
        {
          return entry.status;
        });

      tableBuilder
        .addColumn('date', 'Date')
        .setSize('w-[200px]')
        .usingContentCallback(function (entry)
        {
          return entry.created_at;
        });


      return tableBuilder;
    },
  },
  components: {
    CollectionFilters,
    ResourceListFilters,
    Collection,
    CWidgetStatsB,
    VuePureLightbox,
    CDropdownItem, CDropdownMenu, CDropdownToggle, CDropdown, CWidgetStatsA, CChart, CCol, CRow, CContainer},

  data()
  {
    return {

    }
  },

  mounted()
  {

  }
}
</script>
