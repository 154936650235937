import resourceAPI from "@/fabric/resourceAPI";
import uiAPI from "@/fabric/uiAPI";

import axios from "axios";

export default {
  install (app, config)
  {
    let defaultConfig = {
      menuEndpointName: 'ui/dashboard/menu',
      apiAuthorizationKey: '',
      dashboard: {
        menu: null
      }
    };

    if (typeof config.apiURL === 'undefined')
    {
      console.warn('Fabric was initialized without an apiUrl');
    }
    else if (config.apiURL.endsWith('/') === false)
    {
      config.apiURL += '/';
    }

    this.config = {
      ... defaultConfig,
      ... config
    };

    this.axios = axios.create({
      baseURL: this.config.apiURL,
      headers: {
        'Authorization': this.config.apiAuthorizationKey
      }
    });

    this.axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (401 === error.response.status) {
        window.location.href = '/#/login';
      } else {
        return Promise.reject(error);
      }
    });

    app.config.globalProperties.$fabric = {
        config: this.config,
        axios: this.axios,
        resources: resourceAPI(this, config.apiAuthorizationKey),
        dashboard: uiAPI(this),
    };
  }
};

// $root.$fabric.resource.fetch('holiday', {})
