<template>
  <div>
    <label>{{label}}</label>

    <vue-multiselect :multiple="true"
                     :taggable="params.allowCustomTags"
                  @tag="createOption"
                  class="w-full"
                     label="value"
                     track-by="id"
                  :options="paramOptions"
                     v-model="data"/>
  </div>
</template>

<script>
import {CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";

  export default {
    name: 'ConfigurationEditorTags',
    components: {VueMultiselect, CMultiSelect, CFormSelect},

    props: {
      label: {
        type: String,
        required: true
      },

      params: {
        type: Object,
        required: true
      },

      modelValue: {
        type: Object,
        required: true
      }
    },

    data()
    {
      let data = [];
      let options = [];

      try
      {
        data = this.modelValue ? JSON.parse(this.modelValue) : [];

        data = data.map(option => ({
          id: option,
          value: option
        }))
      }
      catch (e)
      {
        // Do nothing
      }

      for (let key in this.params.options)
      {
        options.push({
          id: this.params.options[key],
          value: this.params.options[key]
        });
      }

      return {
        data: data,
        paramOptions: options
      }
    },

    computed: {
      options()
      {
        return this.data;
      }
    },

    methods: {
      change(values)
      {
        let data = [];

        for (let value of values)
        {
          data.push(value.value);
        }

        this.$emit('update:modelValue', JSON.stringify(data));
      },

      createOption(option)
      {
        this.paramOptions.push({
          id: option,
          value: option
        });
        this.data.push({
          id: option,
          value: option
        });
      }
    },

    watch: {
      data: {
        immediate: true,
        deep: true,
        handler(newVal)
        {
          this.$emit('update:modelValue', JSON.stringify(newVal.map(item => item.value)) );
        }
      }
    }
  }
</script>
