<template>
  <div class="form-control">
    <CFormSelect :label="attribute.label" :options="options" :multiple="false" v-model="value"/>
  </div>
</template>

<script>
import {CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";

export default {
  name: 'ResourceEditorSelectAttribute',

  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    attribute: {
      type: Object,
      required: true
    }
  },

  data()
  {
    return {
      value: this.modelValue,
      mediaResource: null,
      model: [],
    }
  },

  computed: {
    options: function ()
    {
      let options = [];
      for (let key in this.attribute.handler.attributes.options)
      {
        options.push({value: key, label: this.attribute.handler.attributes.options[key]});
      }

      return options;
    }
  },

  watch: {
      value: function (value)
      {
        this.$emit('update:modelValue', value);
      }
  },

  components: {
    CFormSelect,
  }
}
</script>
