<template>
  <template v-for="(section, i) in sections">
    <template v-if="i == activeSection">
      <template v-for="group in section.groups" :key="'group-'+group.id">
        <template v-if="group.fields.length">
          <div :class="sectionWrapperClass">
            <slot name="header" :resource="resource" >
              <div :class="sectionHeaderClass">{{group.name}}</div>
            </slot>

            <transition>
              <div :class="{[sectionBodyClass]: true}" v-show="bodyVisible">
                <template v-for="field in group.fields">
                  <template v-for="attribute in attributes" :key="'attribute-'+attribute.name">
                    <ResourceEditorAttribute
                      v-if="field.type == 'attribute' && field.name == attribute.name"
                      :type="attribute.handler.type"
                      :attribute="attribute"
                      class="!mb-[20px]"
                      :resource="resource"
                      v-model="resource.attributes.attributes[attribute.name]"
                      :context="resource.attributes.attributes"
                      :handler="attribute.handler"
                      :key="'attribute-field-'+resource.attributes.identifier">
                      <template #field="props">
                        <template v-if="props.type == 'repeater'">
                          <ResourceEditorRepeaterAttribute v-model="resource.attributes.attributes[attribute.name]" :attribute="props.attribute"/>
                        </template>
                      </template>
                    </ResourceEditorAttribute>
                  </template>

                  <template v-for="taxonomy in taxonomies" :key="'taxonomy-'+taxonomy.id">
                    <ResourceEditorTaxonomy
                      v-if="field.type == 'taxonomy' && field.name == taxonomy.id"
                      :resource="resource"
                      v-model="resource.attributes.taxonomies[taxonomy.id]"
                      :taxonomy="taxonomy"
                      class="!mb-[20px]"
                    />
                  </template>

                  <slot name="field" :field="field" :resource="resource" :children="children" :childTypes="childTypes"></slot>
                </template>
              </div>
            </transition>

          </div>
        </template>
      </template>
    </template>
  </template>
</template>

<script>
import ResourceEditorAttribute from "./ResourceEditorAttribute.vue";
import ResourceEditorTaxonomy from "./ResourceEditorTaxonomy.vue";
import {CCard, CCardBody, CCardHeader} from "@coreui/vue-pro/dist/esm/components/card";
import {FabricResource} from "@/fabric/resource";
import ResourceEditorRepeaterAttribute from "@/fabric/dashboard/resources/ResourceEditorRepeaterAttribute.vue";

export default {
  name: 'ResourceEditorSections',

  props: {
    modelValue: {
      type: FabricResource,
      required: true
    },

    attributes: {
      type: Array,
      required: true
    },
    taxonomies: {
      type: Array,
      required: true
    },
    children: {
      type: Array,
      required: true
    },
    childTypes:{
      type: Array,
      required: true
    },
    sections: {
      type: Array,
      required: true
    },
    bodyVisible: {
      type: Boolean,
      required: true,
      default: true
    },
    activeSection: {
      type: Number,
      required: true
    },

    sectionWrapperClass: {
      type: String,
      required: false,
      default: ''
    },

    sectionHeaderClass: {
      type: String,
      required: false,
      default: ''
    },

    sectionBodyClass: {
      type: String,
      required: false,
      default: ''
    },
  },

  data()
  {
      return {
        resource: this.modelValue
      }
  },

  watch: {
      resource: {
        handler: function (value)
        {
          this.$emit('update:modelValue', value);
        },

        deep: true
      }
  },

  components: {
    ResourceEditorRepeaterAttribute,
    CCardBody,
    CCardHeader,
    CCard,
    ResourceEditorAttribute,
    ResourceEditorTaxonomy
  }
}
</script>
