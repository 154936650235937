<template>
  <div>
    <a href="#" @click.prevent="openModal" class="block text-center text-[20px]">Solve ReCaptcha</a>
  </div>
</template>

<script>
import {CFormInput, CFormText} from "@coreui/vue-pro/dist/esm/components/form";
import {watch} from "vue";

export default {
  components: {CFormInput, CFormText},
  props: {
    modelValue: {
      required: true,
    },

    payload: {
      required: true,
    },

    id: {
      required: true
    }
  },

  data() {
    return {

    }
  },

  methods: {
    openModal()
    {
      window.open(this.payload.websiteURL+'#sc:'+btoa(JSON.stringify({
        sitekey: this.payload.websiteKey,
        id: this.id
      })),'mywin','width=500,height=500');
    }
  },

  watch: {

  }
}
</script>
