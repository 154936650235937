<template>
  <div class="form-control form-control-date">
    <CFormLabel :for="attribute.id">{{attribute.label}}</CFormLabel>

    <CDatePicker v-model:date="value"
                 :id="attribute.id"
                 class="border-0"
                 :inputDateFormat="(date) => format(new Date(date), 'MMMM dd, yyyy')"/>
  </div>
</template>

<script>
import { format, parse } from 'date-fns'
import {CDatePicker} from "@coreui/vue-pro/dist/esm/components/date-picker";
import {CFormLabel} from "@coreui/vue-pro/dist/esm/components/form";

export default {
  name: 'resource-editor-date-attribute',

  props: {
    modelValue: {
      type: [String, Date],
      required: true
    },

    attribute: {
      type: Object,
      required: true
    }
  },

  data()
  {
      return {
        value: this.modelValue,
        format,
        parse
      }
  },

  watch: {
    modelValue: {
      handler: function (value)
      {
        this.value = value;
      },

      deep: true
    },

    value: {
      handler: function (value)
      {
        this.$emit('update:modelValue', format(value, 'yyyy-MM-dd'));
      },

      deep: true
    }
  },

  components: {
    CFormLabel,
    CDatePicker
  }
}
</script>
