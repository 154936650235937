<template>
  <div v-if="type != 'hidden' && conditional_visibility">
    <template v-if="type == 'text'">
      <CFormInput v-model="attributeValue" :floating-label="attribute.label" :text="handler ? handler.description : null"/>
    </template>

    <template v-if="type == 'number'">
      <CFormInput v-model="attributeValue" :floating-label="attribute.label"/>
    </template>

    <template v-if="type == 'textarea'">
      <CFormTextarea v-model="attributeValue" :floating-label="attribute.label"/>
    </template>

    <template v-if="type == 'date'">
      <ResourceEditorDateAttribute v-model="attributeValue" :attribute="attribute"/>
    </template>

    <template v-if="type == 'media'">
      <ResourceEditorMediaAttribute v-model="attributeValue" :attribute="attribute" :resource="resource"/>
    </template>

    <template v-if="type == 'list'">
      <ResourceEditorListAttribute v-model="attributeValue" :attribute="attribute"/>
    </template>

    <template v-if="type == 'price'">
      <ResourceEditorPriceAttribute v-model="attributeValue" :attribute="attribute"/>
    </template>

    <template v-if="type == 'boolean'">
      <ResourceEditorBooleanAttribute v-model="attributeValue" :attribute="attribute"/>
    </template>

    <template v-if="type == 'select'">
      <ResourceEditorSelectAttribute v-model="attributeValue" :attribute="attribute"/>
    </template>

    <template v-if="type == 'resource_relationship' && handler">
      <ResourceEditorResourceRelationshipAttribute v-model="attributeValue" :attribute="attribute" :handler="handler"/>
    </template>

    <template v-if="type == 'term'">
      <ResourceEditorTermAttribute v-model="attributeValue" :attribute="attribute" :handler="handler"/>
    </template>

    <slot name="field" :type="type" :attribute="attribute" :attributeValue="attributeValue"></slot>
  </div>
</template>

<script>
import {CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CFormInput, CFormLabel, CFormSelect, CFormText, CFormTextarea} from "@coreui/vue-pro/dist/esm/components/form";
import {CDatePicker} from "@coreui/vue-pro/dist/esm/components/date-picker";
import ResourceEditorDateAttribute from "./ResourceEditorDateAttribute.vue";
import ResourceEditorMediaAttribute from "@/fabric/dashboard/resources/ResourceEditorMediaAttribute.vue";
import ResourceEditorListAttribute from "@/fabric/dashboard/resources/ResourceEditorListAttribute.vue";
import ResourceEditorPriceAttribute from "@/fabric/dashboard/resources/ResourceEditorPriceAttribute.vue";
import ResourceEditorBooleanAttribute from "@/fabric/dashboard/resources/ResourceEditorBooleanAttribute.vue";
import ResourceEditorSelectAttribute from "@/fabric/dashboard/resources/ResourceEditorSelectAttribute.vue";
import ResourceEditorResourceRelationshipAttribute
  from "@/fabric/dashboard/resources/ResourceEditorResourceRelationshipAttribute.vue";
import ResourceEditorTermAttribute from "@/fabric/dashboard/resources/ResourceEditorTermAttribute.vue";

export default {
  name: 'ResourceEditorAttribute',

  props: {
    attribute: {
      type: Object,
      required: true
    },

    modelValue: {
      type: [String, Number, Object, Boolean, Array],
      required: true
    },

    type: {
      type: String,
      required: true
    },

    handler: {
      type: Object,
      required: true,
      default: null
    },

    context: {
      type: Object,
      required: false,
      default: null
    },

    resource: {
      type: Object,
      required: false
    }
  },

  data()
  {
      return {
        attributeValue: this.modelValue,
        conditional_visibility: this.attributeIsVisible(this.attribute)
      }
  },

  methods:
    {
      attributeIsVisible: function (attribute)
      {
        if (!attribute.handler)
        {
          return true;
        }

        if (!attribute.handler.conditional_visibility || this.context === null)
        {
          return true;
        }

        for (let conditional_visibility of attribute.handler.conditional_visibility)
        {
          if (conditional_visibility.comparison === '=' && this.context[conditional_visibility.attribute] != conditional_visibility.value)
          {
            return false;
          }

          if (conditional_visibility.comparison === '!=' && this.context[conditional_visibility.attribute] == conditional_visibility.value)
          {
            return false;
          }

          if (conditional_visibility.comparison === '>' && this.context[conditional_visibility.attribute] <= conditional_visibility.value)
          {
            return false;
          }

          if (conditional_visibility.comparison === '<' && this.context[conditional_visibility.attribute] >= conditional_visibility.value)
          {
            return false;
          }

          if (conditional_visibility.comparison === '>=' && this.context[conditional_visibility.attribute] < conditional_visibility.value)
          {
            return false;
          }

          if (conditional_visibility.comparison === '<=' && this.context[conditional_visibility.attribute] > conditional_visibility.value)
          {
            return false;
          }
        }

        return true;
      }
    },

  watch: {
    modelValue: {
      handler: function (value)
      {
        this.attributeValue = value;
      },

      deep: true
    },

    context: {
      handler: function (value)
      {
        this.conditional_visibility = this.attributeIsVisible(this.attribute);
      },

      deep: true
    },

    attributeValue: {
      handler: function (attribute)
      {
        this.$emit('update:modelValue', attribute);
      },

      deep: true
    }
  },

  components: {
    ResourceEditorResourceRelationshipAttribute,
    ResourceEditorBooleanAttribute,
    ResourceEditorPriceAttribute,
    ResourceEditorListAttribute,
    ResourceEditorMediaAttribute,
    ResourceEditorSelectAttribute,
    ResourceEditorTermAttribute,
    CFormTextarea,
    ResourceEditorDateAttribute,
    CDatePicker,
    CFormInput,
    CFormText,
    CFormSelect,
    CFormLabel,
    CRow
  }
}
</script>
