<template>
  <div class="taxonomy-editor">
    <CFormLabel>{{taxonomy.label}}</CFormLabel>
    <CMultiSelect
                 :disabled="!termsLoaded"
                 :options="options"
                  @change="change"
                 :required="taxonomy.configuration.isRequired"
                 :multiple="taxonomy.configuration.allowMultiple"/>

  </div>
</template>

<script>
import {CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CFormInput, CFormLabel, CFormSelect, CFormText} from "@coreui/vue-pro/dist/esm/components/form";
import {CDatePicker} from "@coreui/vue-pro/dist/esm/components/date-picker";
import ResourceEditorDateAttribute from "./ResourceEditorDateAttribute.vue";
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";

export default {
  name: 'ResourceEditorTaxonomy',

  props: {
    resource: {
      type: Object,
      required: true
    },

    taxonomy: {
      type: Object,
      required: true
    },

    modelValue: {
      type: [String, Number, Object, Boolean, Array],
      required: true
    }
  },

  data()
  {
    let value = this.modelValue
      ? this.modelValue.map(term => typeof term === 'object' ? term.id : term) : [];

      return {
        value: value,
        terms: [],
        termsLoaded: false
      }
  },

  mounted()
  {
      this.$root.$fabric.resources.fetchTerms(this.resource.getType(), this.taxonomy.id).then(terms =>
      {
          this.terms = terms;
          this.termsLoaded = true;
      });
  },

  computed: {
    options()
    {
      return this.terms.map(term =>
      {
          return {
              value: term.getId(),
              label: term.getName(),
              selected: this.value.includes(term.getId())
          }
      });
    }
  },

  methods: {
    change(value)
    {
      this.value = value.map(value => value.value);

      this.$emit('update:modelValue', this.value);
    }
  },

  watch: {
    modelValue: {
      handler: function (value)
      {
        this.value = value ? value.map(term => typeof term === 'object' ? term.id : term) : [];
      },

      deep: true
    }
  },

  components: {
    CMultiSelect,
    ResourceEditorDateAttribute,
    CDatePicker,
    CFormInput,
    CFormText,
    CFormSelect,
    CFormLabel,
    CRow
  }
}
</script>
