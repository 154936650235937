import {FabricResourceCollection, FabricResource, FabricTerm} from "@/fabric/resource";
export default (fabric, authorizationKey) =>
{
  return {
    headers(defaultHeaders = {})
    {
      let headers = defaultHeaders;

      if (typeof authorizationKey === 'string')
      {
        headers["Authorization"] = "Bearer " + authorizationKey;
      }
      else if (authorizationKey instanceof Function)
      {
        headers["Authorization"] = "Bearer "+authorizationKey();
      }

      return headers;
    },

    fetch(endpoint, resourceId, params = {})
    {
      return fabric.axios({
        method: 'get',
        url: fabric.config.apiURL + 'resources/' + endpoint + '/' + resourceId,
        params: params,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        })
        .then(result =>
        {
          return new FabricResource(result);
        })
    },

    delete(endpoint, resourceId)
    {
      return fabric.axios({
        method: 'delete',
        url: fabric.config.apiURL + 'resources/' + endpoint + '/' + resourceId,
        headers: {
          "Accept": "application/json; odata=verbose"
        }
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data;
          }
        });
    },

    find(endpoint, params)
    {
      return fabric.axios({
        method: 'get',
        url: fabric.config.apiURL + 'resources/' + endpoint,
        params: params,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        })
        .then(results =>
        {
          return new FabricResourceCollection(results);
        })
    },

    save(endpoint, resource, customOptions = {})
    {
      let options = {
        dryRun: customOptions.dryRun ?? false,
        attachment: customOptions.attachment ?? null,
        params: customOptions.params ?? null
      }

      let url = fabric.config.apiURL +'resources/' + endpoint;

      if (resource.getId())
      {
        url += '/' + resource.getId();
      }

      let postData = {
        ... resource.getProperties(),
        ... options.params
      };

      let headers = {
        //"Accept": "application/json; odata=verbose"
      };

      if (options.dryRun)
      {
        headers['X-Dry-Run'] = 'true';
      }

      // Attach file
      if (options.attachment)
      {
        postData.attachment = options.attachment;

        headers['Content-Type'] = 'multipart/form-data';
      }

      return fabric.axios
        .post(url, postData, {
          headers: this.headers(headers)
        })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        })
        .then(result =>
        {
          return new FabricResource(result);
        });
    },

    fetchEndpoint(resource, endpoint)
    {
      let url = fabric.config.apiURL +'resources/' + resource + "/" + endpoint;

      return fabric.axios({
        method: 'get',
        url: url,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    fetchStatuses(resource)
    {
      return this.fetchEndpoint(resource,'statuses')
        .then(response => response.results);
    },

    fetchAttributes(endpoint, params = [])
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/attributes";

      return fabric.axios({
        method: 'get',
        url: url,
        params: params,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data.results;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    fetchTaxonomies(endpoint)
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/taxonomies";

      return fabric.axios({
        method: 'get',
        url: url,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data.results;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    fetchChildren(endpoint)
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/children";

      return fabric.axios({
        method: 'get',
        url: url,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data.results;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    fetchTerms(endpoint, taxonomy)
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/taxonomies/" + taxonomy + "/terms";

      return fabric.axios({
        method: 'get',
        url: url,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data.results.map((termAttributes) => new FabricTerm(termAttributes));
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    fetchTerm(endpoint, taxonomy, termId)
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/taxonomies/" + taxonomy + "/terms/" + termId;

      return fabric.axios({
        method: 'get',
        url: url,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return new FabricTerm(response.data);
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    fetchTaxonomyAttributes(endpoint, taxonomy, params = [])
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/taxonomies/" + taxonomy + "/attributes";

      return fabric.axios({
        method: 'get',
        url: url,
        params: params,
        headers: this.headers({"Accept": "application/json; odata=verbose"})
      })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return response.data.results;
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },

    saveTerm(endpoint, taxonomy, term)
    {
      let url = fabric.config.apiURL +'resources/' + endpoint + "/taxonomies/" + taxonomy + "/terms";

      if (term.getId())
      {
        url += '/' + term.getId();
      }

      let postData = term.getProperties();

      return fabric.axios
        .post(url, postData, {
          headers: this.headers({"Accept": "application/json; odata=verbose"})
        })
        .then(response => {
          if (response.headers['content-type'] === 'application/json')
          {
            return new FabricTerm(response.data);
          }
          else
          {
            throw new Error('Invalid content-type:'+ response.headers['content-type']);
          }
        });
    },
  }
}
