<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Login</span>
      </h4>

      <CFormInput floating-label="Email"
                  type="text"
                  class="w-full"
                  v-model="username"/>

      <CFormInput floating-label="Password"
                  type="password"
                  class="w-full"
                  v-model="password"/>

      <CButton color="primary" @click.prevent="login">Login</CButton>
    </div>
  </div>
</template>

<script>
import {CCol, CContainer, CRow} from "@coreui/vue-pro/dist/esm/components/grid";
import {CChart} from "@coreui/vue-chartjs";
import {CWidgetStatsA, CWidgetStatsB} from "@coreui/vue-pro/dist/esm/components/widgets";
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/vue-pro/dist/esm/components/dropdown";
import Collection from "@/fabric/ui/Collection.vue";
import {TableBuilder} from "@/fabric/UI";
import {CFormInput, CFormText} from "@coreui/vue-pro/dist/esm/components/form";
import {CButton} from "@coreui/vue-pro/dist/esm/components/button";

export default {
  components: {
    CButton,
    CFormInput,
    CFormText,
    Collection,
    CWidgetStatsB,
    CDropdownItem, CDropdownMenu, CDropdownToggle, CDropdown, CWidgetStatsA, CChart, CCol, CRow, CContainer},

  data()
  {
    return {
      username: '',
      password: '',
    }
  },

  mounted()
  {

  },

  methods: {
    login()
    {
      this.$root.$fabric.axios.post('/auth/login', {
        email: this.username,
        password: this.password
      }).then((response) =>
      {
        if (response.data.error)
        {
          this.notificationStore.notifications.push({
            title: response.data.error,
            color: 'error'
          });
        }
        else
        {
          localStorage.setItem('session', response.data.token);

          window.location.href = '/';
        }
      })
    }
  }
}
</script>
