<template>
    <ui-table
            :columns="columns"
            :id="id"
            :entries="entries"
            :entryClasses="entryClasses"
            v-model="orderby"
            :primary-column-size="primaryColumnSize"

            :total="total"
            :results-per-page="resultsPerPage"
            :pagination-enabled="paginationEnabled"
            :page="page"
            :sticky="sticky"

            :rounding="rounding"
            :shadow="shadow"
            :border="border"
            :header-background="headerBackground"
            :header-text="headerText"
            :entry-padding="entryPadding"
            :pagination-class="paginationClass"
            :class="{'opacity-50': loading}"
            :groups="groups"
            :loading="loading_state"
            :data="table.getData()"
            :actions="actions"
            :state="state"

            :pagination-show-stats="paginationShowStats"
            @paginate="paginate">
        <template v-slot:controls>
          <template v-if="allowPause">
            <template v-if="!paused">
              <a href="#" @click.prevent="paused = true">
                <svg fill="#868686" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="16px" height="16px" viewBox="0 0 277.338 277.338"
                     xml:space="preserve">
                    <g>
                      <path d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665
                        c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z"/>
                      <path d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652
                        V45.665C263.109,20.454,246.394,0,225.78,0z"/>
                    </g>
                    </svg>
              </a>
            </template>

            <template v-if="paused">
              <a href="#" @click.prevent="paused = false">
                <svg fill="#868686" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     width="16px" height="16px" viewBox="0 0 163.861 163.861"
                     xml:space="preserve">
                  <g>
                    <path d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275
                      c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"/>
                  </g>
                  </svg>
              </a>
            </template>
          </template>
        </template>

      <template v-slot:icon="{entry}">
        <slot name="icon" :entry="entry"/>
      </template>

      <template v-slot:entry-footer="{entry}">
        <slot name="entry-footer" :entry="entry"/>
      </template>
    </ui-table>
</template>

<script>
import UITable from './/UITable.vue';
import {TableBuilder} from "@/fabric/ui/TableBuilder";

export default
{
    name: "Collection",

    props: {
        table: {
          required: true
        },

        endpoint: {
          type: String,
          required: true
        },

        resultsPerPage: [Number, String],

      paginationEnabled: {
          type: Boolean,
          default: true
      },

        filters: {
            type: [Array, Object],
            default: []
        },

        params: Object,
        sticky: true,

        id: {
            type: String,
            default: null
        },

        allowPause: {
            type: Boolean,
            default: false
        },

        rounding: {
            default: 'rounded',
            type: String
        },

        shadow: {
            default: '',
            type: String
        },

        border: String,

        headerBackground: {
            type: String,
            default: 'bg-purple'
        },

        headerText: {
            type: String,
            default: 'text-blue'
        },

        entryPadding: {
            type: String,
        },

        syncInterval: {
            type: Number,
            default: 0
        },

        syncAnimateLoading: {
            type: Boolean,
            default: true
        },

        orderBy: {
            type: String,
            default: null
        },

        // object with entry_id: {name}
        groupBy: {
            type: Function,
            default: () => false
        },

        stateManager: {
            type: Function,
            default: () => false
        },

        stateManagerInterval: {
            type: Number,
            default: 10
        },

        paginationShowStats: {
            type: Boolean,
            default: false
        },

        paginationClass: String,

        actions: {
            type: Array,
            default: []
        },

        showTotalResultsCount: {
          type: Boolean,
          default: true
        }
    },

    data()
    {
        return {
            page: 1,
            total: 0,
            entries: [],
            orderby: this.orderBy,
            loading: this.syncAnimateLoading,
            loading_state: true,
            loaded: false,

            syncIntervalHandler: null,
            stateManagerIntervalHandler: null,

            entryClasses: {},
            prevFilters: this.filters,

            controller: null,

            searchTimeout: null,
            paused: false,

          state: null,
        }
    },

    mounted()
    {
        this.fetchEntries().finally(() =>
        {
            this.loaded = true;
        });

        if (this.syncInterval > 0)
        {
            this.syncIntervalHandler = setInterval(() =>
            {
                console.warn('Syncing...', this.page);

                if (this.paused || document.hidden)
                {
                  return;
                }

                if (this.page > 1)
                {
                    return;
                }

                this.fetchEntries().finally(() =>
                {
                    this.loaded = true;
                })
            }, this.syncInterval * 1000);
        }

        if (this.stateManagerInterval > 0 && typeof this.stateManager === 'function')
        {
            this.syncIntervalHandler = setInterval(() =>
            {
              if (!document.hidden)
              {
                this.syncState();
              }

            }, this.stateManagerInterval * 1000);
        }
    },

    beforeUnmount()
    {
        if (this.syncIntervalHandler)
        {
            console.warn('Cleared sync interval');

            clearInterval(this.syncIntervalHandler);
        }
    },

    watch: {
        filters:
        {
          handler: function(filters)
          {
            this.page = 1;

            this.prevFilters = filters;

            if (this.searchTimeout)
            {
              clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = setTimeout(() =>
            {
              console.warn('timeout passed...');
              this.fetchEntries();
            }, 500);
          },

          deep: true
        },

      endpoint()
      {
        this.fetchEntries();
      },

        params()
        {
            this.fetchEntries()
        },

        resultsPerPage()
        {
            this.page = 1;

            this.fetchEntries();
        },

        page()
        {
            this.fetchEntries();
        },

        orderby()
        {
            this.fetchEntries();
        }
    },

    methods: {
        syncState: function ()
        {
            let statePromise = this.stateManager(
                // pass active entry ids
                Object.values(this.entries.map(entry => entry.id))
            );

            if (statePromise instanceof Promise)
            {
                statePromise.then((state) =>
                {
                    this.state = state;
                });
            }
        },

        paginate(value)
        {
            this.page = value;
        },

        fetchEntries()
        {
            let params = {
                ...this.filters,
                page: this.page,
                limit: this.resultsPerPage,
                orderby: this.orderby,

                ... this.params
            };

            this.loading_state = true;

            if (this.controller)
            {
                this.controller.abort();
            }

            this.controller = new AbortController();

            this.loading = this.syncAnimateLoading;

            return this.$root.$fabric.axios.get(this.endpoint, {params: params, signal: this.controller.signal})
                .then((response) =>
            {
                this.entries = response.data.results.map((entry) => (this.transformCallback)(entry));
                this.total = response.data.total;
                this.loading = false;
                this.loading_state = false;

                this.$emit('loadedEntries', this.entries);

                if (typeof this.stateManager === 'function')
                {
                    this.syncState();
                }

                return this.entries;
            });
        }
    },

    computed: {
      columns()
      {
        return this.table.getColumns().map((column) =>
        {
          return {
            id: column.getId(),
            name: column.getName(),
            content: column.getContent(),
            component: column.getComponent(),
            sortable: column.isSortable(),
            size: column.getSize(),
          }
        });
      },

      transformCallback()
      {
          if (this.table.getTransformCallback())
          {
            return this.table.getTransformCallback();
          }

          return (entry) => entry;
      },

      groups()
      {
        let groups = {};
        let prev_entry = null;
        let group = null;

        for (let entry of this.entries)
        {
          group = this.groupBy(entry, prev_entry);

          if (group)
          {
            groups[entry.id] = group;
          }

          prev_entry = entry;
        }

        return groups;
        },

      primaryColumnSize()
      {
        return this.table.getPrimaryColumnSize();
      }
    },

    components: {
        'ui-table': UITable
    }
}
</script>
