<template>
  <CFormInput :floating-label="label"
              type="text"
              class="w-full"
              v-model="data"/>
</template>

<script>
  import {CFormInput} from "@coreui/vue-pro/dist/esm/components/form";

  export default {
    name: 'ConfigurationEditorText',
    components: {CFormInput},

    props: {
      label: {
        type: String,
        required: true
      },

      params: {
        type: Object,
        required: true
      },

      modelValue: {
        type: Object,
        required: true
      }
    },

    data()
    {
      return {
        data: this.modelValue
      }
    },

    watch: {
      modelValue: {
        immediate: true,
        handler(newVal)
        {
          this.data = newVal;
        }
      },

      data: {
        deep: true,
        handler(newVal, oldVal)
        {
          this.$emit('update:modelValue', newVal);
        }
      }
    }
  }
</script>
