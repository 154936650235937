import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

import defaultRoutes from '@/fabric/dashboard/routes';

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    children: [
      {
        path: '/not-found',
        name: 'Not Found',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '/',
        name: 'Instances',
        component: () => import('@/views/pages/Home.vue'),
      },
      {
        path: '/captcha',
        name: 'Captcha',
        component: () => import('@/views/pages/Captcha.vue'),
      },
      {
        path: 'feed',
        name: 'Feed',
        component: () => import('@/views/pages/Feed.vue'),
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/pages/Login.vue'),
      },
      {
        path: '/instance/:id',
        name: 'Instance Editor',
        component: () => import('@/views/pages/InstanceEditor.vue'),
      },
      {
        path: '/instance/new',
        name: 'New Instance',
        component: () => import('@/views/pages/InstanceEditor.vue'),
      },
      {
        path: '/hits/:instanceId',
        name: 'Hits',
        component: () => import('@/views/pages/Hits.vue'),
      },
    ],
  },

  defaultRoutes
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
