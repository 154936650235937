<template>
  <div>
    <div class="flex flex-row gap-[20px]">
      <div v-for="filter in filterBuilder" :key="'filter_'+filter.getId()" :class="{'w-[300px]': true, [filter.getWrapperClass()]: true}">
        <template v-if="filter.getType() === 'text'">
          <div class="w-full">
            <label class="form-label">{{filter.getName()}}</label>
            <CFormInput type="text" size="md" v-model="filters[filter.getId()]"/>
          </div>
        </template>

        <template v-if="filter.getType() === 'select'">
          <CMultiSelect class="w-full" size="md" :label="filter.getName()" :options="filter.getOptions().value" @change="(value) => filters[filter.getId()] = value.map((value) => value.value)"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {ResourceConfiguration} from "@/fabric/dashboard";
import {CFormInput} from "@coreui/vue-pro/dist/esm/components/form";
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";
import {CAlert} from "@coreui/vue-pro/dist/esm/components/alert";
import {FilterBuilder} from "@/fabric/ui/FilterBuilder";

export default {
  name: 'collection-filters',
  components: {CAlert, CMultiSelect, CFormInput},

  props: {
    modelValue: {
      type: Object,
      required: true
    },

    builder: {
      type: FilterBuilder,
      required: true
    }
  },

  data()
  {
    return {
      filters: this.modelValue,
      filterBuilder: this.builder.getFilters()
    }
  },

  watch: {
    modelValue: {
      handler: function ()
      {
        this.filters  = this.modelValue;
      },

      deep: true
    },

    filters: {
      handler: function ()
      {
        this.$emit('update:modelValue', this.filters);
      },

      deep: true
    }
  },

  methods: {
    update_select(type)
    {
      console.log('update_select', type);
    }
  }
}
</script>
