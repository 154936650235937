<template>
  <div class="m-4" v-if="configuration">
    <h4 class="mb-3">
      {{ pageTitle }}

      <a href="#" class="btn btn-sm btn-primary ml-[20px]" @click.prevent="navigateToAddNew">Add New</a>
    </h4>
    <Collection :endpoint="endpoint" :table="configuration.getTermsTableBuilder()"></Collection>
  </div>
</template>
<script>
import Collection from '@/fabric/ui/Collection.vue';

export default {
  data()
  {
    return {
      configuration: null
    }
  },

  mounted()
  {
    this.$root.$fabric.axios.get(this.baseEndpoint)
      .then(response => this.configuration = response.data)
  },

  computed: {
    configuration()
    {
      return this.$root.$fabric.dashboard.getResourceConfiguration(
        this.$route.params.resourceType
      );
    },

    baseEndpoint()
    {
      return this.$root.$fabric.config.apiURL + "resources/" + this.$route.params.resourceType + "/taxonomies/" + this.$route.params.taxonomyId;
    },

    endpoint()
    {
      return this.baseEndpoint + "/terms";
    },

    pageTitle()
    {
      if (!this.configuration)
      {
        return "List";
      }

      return this.configuration.label;
    }
  },

  methods: {
    navigateToAddNew()
    {
      this.$router.push({
        name: 'term-editor-new',
        params: {
          resourceType: this.$route.params.resourceType,
          taxonomyId: this.$route.params.taxonomyId
        }
      })
    }
  },

  components: {
    Collection
  }
}
</script>
