<template>
  <div class="m-4">
    <div class="flex gap-[20px] flex-col">
      <h4>
        <span>Captcha</span>

        <CaptchaRequest v-for="captchaRequest in captcha"
                        :type="captchaRequest.type"
                        :payload="captchaRequest.payload"
                        :key="captchaRequest.id"
                        :id="captchaRequest.id"
                        @solved="captcha.splice(captcha.indexOf(captchaRequest), 1)"/>
      </h4>
    </div>
  </div>
</template>

<script>
import CaptchaRequest from "@/views/components/CaptchaRequest.vue";

export default {
  components: {CaptchaRequest},
  data()
  {
    return {
      captcha: [],
      intervalId: null
    }
  },

  mounted()
  {
    this.fetch_captcha();

    this.interval = setInterval(() => this.fetch_captcha(), 1000); // Fetch new captcha every minute
  },

  beforeDestroy()
  {
    clearInterval(this.interval);
  },

  methods: {
    fetch_captcha()
    {
      this.$root.$fabric.axios.get('captcha', {
        params: {
          preferred_provider: 'local',
          status: 1,
          orderby: '+id'
        }
      })
        .then((response) => this.captcha = response.data.results);
    }
  }
}
</script>
