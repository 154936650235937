<template>
  <div>
    <h6>Check (<a href="#" @click.prevent="$emit('deleted')">Delete</a>)</h6>

    <CFormSelect
      aria-label="Provider"
      v-model="check.provider"
      id="provider"
      floating-label="Provider"
      :options="providerOptions"
    class="mb-[20px]">
    </CFormSelect>

    <ConfigurationEditor v-model="check.configuration"
                         :configuration="provider.configuration"
                         v-if="provider"></ConfigurationEditor>
  </div>
</template>

<script>
import {CCard, CCardBody, CCardHeader} from "@coreui/vue-pro/dist/esm/components/card";
import {CFormSelect} from "@coreui/vue-pro/dist/esm/components/form";
import ConfigurationEditor from "@/views/components/ConfigurationEditor.vue";

export default {
  name: 'MatcherSectionGroupCheck',
  components: {ConfigurationEditor, CFormSelect, CCard, CCardHeader, CCardBody},

  props: {
    modelValue: {
      type: Array,
      required: true
    },
  },

  data()
  {
    return {
      selectedItems: [],
      check: this.modelValue,
      providers: []
    }
  },

  mounted()
  {
      this.$root.$fabric.axios.get('/matchers')
       .then(({data}) => {
          this.providers = data.results;
        })
  },

  watch: {
    modelValue(newVal) {
      this.groups = newVal;
    },

    check: {
      deep: true,
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },

  computed: {
    providerOptions()
    {
      let options = [
        {
          value: '',
          label: 'Select Provider'
        }
      ];

      for (let provider of this.providers)
      {
        options.push({
          value: provider.id,
          label: provider.name
        });
      }

      return options;
    },

    provider()
    {
      return this.providers.find(provider => provider.id === this.check.provider);
    }
  }
}
</script>
