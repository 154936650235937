<template>
  <div class="form-control">
    <CMultiSelect :label="attribute.label" :options="options" :multiple="handler.attributes.allow_multiple" @change="change" v-model="value"/>
  </div>
</template>

<script>
import {CMultiSelect} from "@coreui/vue-pro/dist/esm/components/multi-select";

export default {
  name: 'ResourceEditorResourceRelationshipAttribute',

  props: {
    modelValue: {
      type: String,
      default: '',
      required: true
    },

    attribute: {
      type: Object,
      required: true
    },

    handler: {
      type: Object,
      required: true
    }
  },

  data()
  {
    let value = this.modelValue;

    if (this.modelValue && typeof this.modelValue === 'string' && JSON.parse(this.modelValue))
    {
      value = JSON.parse(this.modelValue);
    }
    else if (Number.isInteger(this.modelValue))
    {
      value = [this.modelValue];
    }
    else if (!Array.isArray(this.modelValue))
    {
      value = [];
    }

    return {
      value: value,
      mediaResource: null,
      configuration: null,
      model: [],
      resources: null
    }
  },

  mounted()
  {
    this.$root.$fabric.resources
      .fetchEndpoint('holiday_date', 'configuration')
      .then((configuration) => this.configuration = configuration);

      this.$root.$fabric.resources
        .find(this.handler.attributes.resource_type, this.handler.attributes.params)
        .then((resources) => this.resources = resources.getResults());
  },

  computed: {
    options: function ()
    {
      let options = [];

      if (this.resources && this.configuration)
      {
        for (let resource of this.resources)
        {
          let name = resource.getName() ?? this.configuration.name;

          if (this.configuration.highlight_attribute && resource.getAttribute(this.configuration.highlight_attribute))
          {
            name += ' ('+resource.getAttribute(this.configuration.highlight_attribute)+')';
          }

          options.push({
            value: resource.getId(),
            label: name,
            selected: Array.isArray(this.value) ? this.value.includes(resource.getId()) : this.value == resource.getId()
          });
        }
      }

      return options;
    }
  },

  methods: {
      change: function (value)
      {
        this.value = value.map((item) => item.value);
      }
  },

  watch: {
      value: function (value)
      {
        this.$emit('update:modelValue', value);
      }
  },

  components: {
    CMultiSelect
  }
}
</script>
