<template>
  <a href="#" @click.prevent="navigateTo" class="link-dark text-decoration-none">
    {{ entry.getName() }}

    <template v-if="!entry.getName()">
      <span class="fw-normal">Unnamed</span>
    </template>
  </a>
</template>

<script>
export default {
  props: {
    entry: {
      type: Object,
      required: true
    }
  },

  methods: {
    navigateTo()
    {
      this.$router.push({
        name: 'resource-editor',
        params: {
          resourceType: this.entry.getType(),
          resourceId: this.entry.getId()
        }
      })
    }
  }
}
</script>
