<template>
  <div :class="{'child-editor-child my-[20px]': true, [wrapperClass]: true}">
    <template v-for="(section, index) in sections" v-if="!loading">
      <ResourceEditorSections
        v-if="taxonomies && attributes && sections"
        :activeSection="index"
        :sections="sections"
        :children="[]"
        :taxonomies="taxonomies"
        :attributes="attributes"
        v-model="resource"
        :body-visible="bodyVisibility"
        section-wrapper-class="child-editor-section-wrapper mb-[20px]"
        section-body-class="child-body"
        section-header-class="child-header child-editor-section-header mb-[10px]">

        <template #header="props" :resource="resource">
          <div :class="{'child-editor-header border-[#dbdfe6] border-solid mb-[20px]': true, 'pb-[10px] border-b': bodyVisibility}">
            <div class="align-middle inline-block cursor-pointer select-none" @click="bodyVisibility = !bodyVisibility">
              <slot name="header-title" :resource="resource"></slot>
            </div>
            <div class="controls flex gap-[14px] float-right align-middle">
              <slot name="header-controls" :resource="resource"></slot>

              <a @click.prevent="$emit('deleted', resource)" href="#" class="text-[#ff88a4]" v-c-tooltip="{content: 'Delete', placement: 'bottom'}">
                <CIcon :name="cilTrashX()" size="lg" />
              </a>

              <template v-if="visibleAttributes">
                <template v-if="bodyVisibility">
                  <a @click.prevent="bodyVisibility = false" href="#" v-c-tooltip="{content: 'Toggle', placement: 'bottom'}">
                    <CIcon :name="cilEyeSlash()" size="lg" />
                  </a>
                </template>

                <template v-else>
                  <a @click.prevent="bodyVisibility = true" href="#" v-c-tooltip="{content: 'Toggle', placement: 'bottom'}">
                    <CIcon :name="cilEye()" size="lg" />
                  </a>
                </template>
              </template>

              <div class="flex gap-[5px]">
                <a @click.prevent="$emit('move-up', resource)" href="#" class="text-[#b6b9c1] hover:text-black" v-c-tooltip="{content: 'Move Up', placement: 'bottom'}">
                  <CIcon :name="cilArrowThickFromBottom()" size="lg"/>
                </a>
                <a @click.prevent="$emit('move-down', resource)" href="#" class="text-[#b6b9c1] hover:text-black" v-c-tooltip="{content: 'Move Down', placement: 'bottom'}">
                  <CIcon :name="cilArrowThickFromTop()" size="lg" />
                </a>
              </div>
            </div>
          </div>
        </template>

        <template #header-title="props">
          <slot name="header-title" :resource="props.resource"></slot>
        </template>

        <template #header-controls="props">
          <slot name="header-controls" :resource="props.resource"></slot>
        </template>
      </ResourceEditorSections>
    </template>

    <div v-if="loading">
      <CSpinner size="sm"/> Loading...
    </div>
  </div>
</template>

<script>
import ResourceEditorSections from "@/fabric/dashboard/resources/ResourceEditorSections.vue";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem
} from "@coreui/vue-pro/dist/esm/components/accordion";
import {CSpinner} from "@coreui/vue-pro/dist/esm/components/spinner";
import {cilEye, cilEyeSlash, cilTrashX} from "@coreui/icons-pro";
import {cilArrowThickFromBottom, cilArrowThickFromTop} from "@coreui/icons";

export default {
  name: 'ResourceEditorChild',
  components: {CSpinner, CAccordionBody, CAccordionHeader, CAccordionItem, CAccordion, ResourceEditorSections},

  props: {
    modelValue: {
      type: Array,
      required: true
    },

    baseAttributes: {
      type: Array,
      required: true
    },

    wrapperClass: {
      type: String,
      required: true,
      default: ''
    },

    taxonomies: {
      type: Array,
      required: true
    },

    sections: {
      type: Array,
      required: true
    },

    configuration: {
      type: Object,
      required: true
    }
  },

  data()
  {
    return {
      resource: this.modelValue,
      attributes: this.baseAttributes,
      dynamicAttributesLoaded: !this.configuration.dynamic_attributes,
      bodyVisibility: !this.modelValue.getId()
    }
  },

  mounted()
  {
    if (this.configuration.dynamic_attributes)
    {
      this.$root.$fabric.resources
        .fetchAttributes(this.resource.attributes.type, this.resource.attributes.attributes)
        .then((attributes) =>
        {
          this.attributes = attributes;
          this.dynamicAttributesLoaded = true;
        })
    }
  },

  computed: {
    visibleAttributes()
    {
      return 1;
    },

    loading()
    {
      return !this.dynamicAttributesLoaded || this.attributes == null || this.sections == null || this.taxonomies == null;
    },

      resourceWrap()
      {
        return JSON.parse(JSON.stringify(this.resource))
      },

    sections()
    {
      let matchedFields = [];

      if (!this.configuration || !this.attributes || !this.taxonomies || this.loading)
      {
        return [];
      }

      let sections = JSON.parse(JSON.stringify(this.configuration.sections));

      return sections.map((section) =>
      {
        let groups = section.groups.map((group) =>
        {
          let fields = [];

          for (let selector of group.selectors)
          {
            for (let attribute of Object.values(this.attributes))
            {
              let fieldData = {
                type: 'attribute',
                name: attribute.name
              };

              if (this.selectorMatchesAttribute(selector, attribute))
              {
                fields.push(fieldData);
              }
            }

            for (let taxonomy of Object.values(this.taxonomies))
            {
              let fieldData = {
                type: 'taxonomy',
                name: taxonomy.id
              };

              if (this.selectorMatchesTaxonomy(selector, taxonomy))
              {
                fields.push(fieldData);
              }
            }
          }

          return {
            name: group.name,
            fields: fields
          };
        });

        for (let groupId in groups)
        {
          groups[groupId].fields = groups[groupId].fields
            .filter((value, index, arr) => arr.indexOf(value) === index)
            .filter((field) => matchedFields.map((field) => field.type+":"+field.name).indexOf(field.type+":"+field.name) === -1);

          matchedFields = matchedFields.concat(groups[groupId].fields);
        }

        section.groups = groups.filter((group) => group.fields.length);

        return section;
      }).filter((section) => section.groups.length > 0);
    }
  },

  watch: {
    resourceWrap: {
      handler: function(newValue, oldValue)
      {
        if (!this.configuration.dynamic_attributes)
        {
          return;
        }

        let changed = false;

        for (let attribute of this.baseAttributes)
        {
          if (newValue.attributes.attributes[attribute.name] != oldValue.attributes.attributes[attribute.name])
          {
            changed = true;
          }
        }

        if (changed)
        {
          this.loading = true;

          this.$root.$fabric.resources
            .fetchAttributes(this.resource.attributes.type, this.resource.attributes.attributes)
            .then((attributes) =>
            {
              this.attributes = attributes;
              this.loading = false;
            })
        }
      },

      deep: true
    }
  },

  methods: {
    cilEye() {
      return cilEye
    },
    cilEyeSlash() {
      return cilEyeSlash
    },
    cilArrowThickFromTop() {
      return cilArrowThickFromTop
    },
    cilArrowThickFromBottom() {
      return cilArrowThickFromBottom
    },
    cilTrashX() {
      return cilTrashX
    },

    selectorMatchesChild(selector, child)
    {
      if ('child' != selector.type)
      {
        return false;
      }

      if (selector.selector == "*")
      {
        return true;
      }

      if (selector.selector == child.type)
      {
        return true;
      }

      return false;
    },

    selectorMatchesAttribute(selector, attribute)
    {
      if ('attribute' != selector.type)
      {
        return false;
      }

      if (selector.selector == "*")
      {
        return true;
      }

      if (selector.selector == attribute.name)
      {
        return true;
      }

      return false;
    },

    selectorMatchesTaxonomy(selector, taxonomy)
    {
      if ('taxonomy' != selector.type)
      {
        return false;
      }

      if (selector.selector == "*")
      {
        return true;
      }

      if (selector.selector == taxonomy.id)
      {
        return true;
      }

      return false;
    },
  }
}
</script>
