<template>
  <span :class="{'cursor-pointer select-none': true, 'bold': true}">
    <template v-if="state === null">
      <span class="text-orange">Loading</span>
    </template>

    <template v-else-if="!job">
      <span class="text-red">Offline</span>
    </template>

    <template v-else>
      <span :class="{'text-green': job.heartbeat_type == 1 && !job.scheduled_restart_at, 'text-orange': job.heartbeat_type == 1 && job.scheduled_restart_at, 'text-red': !job.heartbeat_type}">{{job.status}}</span>

      <template v-if="job.heartbeat_at_delta !== null">
        <span class="text-muted">&nbsp;-&nbsp;{{job.heartbeat_at_delta}}s ago</span>
      </template>
    </template>
  </span>
</template>

<script>
import {CTooltip} from "@coreui/vue-pro/dist/esm/components/tooltip";
import {ref} from "vue";

export default {
  components: {CTooltip},
  props: {
    entry: {
      type: Object,
      required: true
    },

    state: {
      type: [Object, Array]
    }
  },

  computed: {
    job()
    {
      if (!this.state)
      {
        return null;
      }

      if (!this.state[this.entry.id])
      {
        return null;
      }

      return this.state[this.entry.id].job;
    }
  },

  methods: {
    ref,
    refreshStatus(clearJob = true)
    {
      this.isRefreshing = true;

      if (clearJob)
      {
        this.job = null;
      }

      this.$root.$fabric.axios.get('jobs', {
        params: {
          instance_id: this.entry.id
        }
      }).then((response) =>
      {
        this.isRefreshing = false;

        if (response.data.results.length)
        {
          this.job = response.data.results[0] ;
        }
        else if (!clearJob)
        {
          this.job = null;
        }
      })
    }
  }
}
</script>
